.progress-bar-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    min-width: 1000px;
  }
  
  .progress-step {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    position: relative;
    text-align: center;
  }
  
  .progress-step .step-icon {
    width: 40px; /* Minimalista: Pequeno */
    height: 40px;
    border-radius: 50%;
    background: #ddd; /* Cinza claro para passos não concluídos */
    margin-bottom: 5px;
  }
  
  .bs-stepper-line {
    height: 2px;
    background: #ddd;
    flex: 1;
    margin: 0 8px; /* Espaçamento entre ícones e linhas */
  }
  
  .bs-stepper-line.completed {
    background: #0d6efd; /* Azul para linhas conectando passos concluídos */
  }
  
  .step-title {
    font-size: 12px;
    color: #333;
    margin-top: 5px;
    font-weight: 600;
  }
  
  .step-description {
    font-size: 10px;
    color: #888;
    margin-top: 2px;
  }

  .bs-stepper-line {
    flex: 1;
    height: 2px;
    background: #ddd;
    margin: 0 5px;
  }
  
  .bs-stepper-line.completed {
    background: #0d6efd;
  }

  .bs-stepper-line, .bs-stepper .line {
    flex: 1 0 32px;
    min-width: 1px;
    min-height: 1px;
    margin: auto;
    background-color: rgba(0, 0, 0, .12);
}

.step-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px; /* Aumentado para destacar mais */
    height: 40px;
    border-radius: 50%;
    background: #f4f4f4; /* Fundo claro para não ativos */
    color: #333; /* Cor do número */
    font-size: 16px; /* Tamanho maior para o número */
    font-weight: bold;
    margin-bottom: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Sombra para destacar */
    transition: background-color 0.3s ease, color 0.3s ease, transform 0.3s ease;
}

.progress-step.active .step-icon {
    background: #0d6efd; /* Fundo azul para passo atual */
    color: #fff; /* Cor branca para número */
    transform: scale(1.1); /* Leve ampliação para destacar */
}

.progress-step.completed .step-icon {
    background: #0d6efd; /* Fundo azul para passos concluídos */
    color: #fff;
}

/* Estilo personalizado para o React-Select */
.css-1s2u09g-control {
  border-color: #ced4da !important;
  border-radius: 4px !important;
  font-size: 14px;
}

.css-1pahdxg-control {
  border-color: #80bdff !important;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25) !important;
}

/* CustomerCarePrint.css */
@media print {
  body {
      margin: 0;
      font-family: 'Arial', sans-serif;
      background-color: #fff;
      color: #000;
  }

  #report-preview {
      width: 100%;
      max-width: 800px;
      margin: 20px auto;
      padding: 20px;
      border: 2px solid #000;
      background-color: #f9f9f9;
  }

  #report-preview h5 {
      text-align: center;
      font-size: 20px;
      font-weight: bold;
      text-transform: uppercase;
      margin-bottom: 20px;
  }

  #report-preview p {
      margin: 10px 0;
      font-size: 16px;
      line-height: 1.5;
  }

  #report-preview .section-title {
      margin-top: 20px;
      font-weight: bold;
      text-decoration: underline;
      font-size: 18px;
  }

  #report-preview .content-block {
      margin: 10px 0;
      padding: 10px;
      border: 1px solid #ccc;
      background-color: #fff;
  }

  @page {
      size: A4; /* Ajusta para tamanho A4 */
      margin: 15mm;
  }
}

.country-select .select__control {
  height: 38px;
}

.country-select .select__value-container {
  padding: 0 8px;
}

.d-flex .form-control {
  flex: 1;
}