/* src/components/Timer.css */

.timer-container {
    background-color: #ffffff;
    text-align: center;
    width: 100%;
    margin: auto;
  }
  
  .timer-label {
    font-size: 1.2rem;
    margin-bottom: 10px;
    color: #333;
    text-transform: uppercase;
    letter-spacing: 1px;
  }

  .timer-display {
    font-size: 2rem;
    font-weight: bold;
    color: #007bff;
    padding: 10px;
    border: 2px solid #007bff;
    border-radius: 8px;
    text-align: center;
    width: 100%;
    box-sizing: border-box;
}
  
  .timer-buttons .btn {
    transition: background-color 0.3s ease, transform 0.3s ease;
  }
  
  .timer-buttons .btn:hover {
    transform: scale(1.05);
  }
  
  .timer-buttons .btn-success {
    background-color: #28a745;
    border-color: #28a745;
  }
  
  .timer-buttons .btn-warning {
    background-color: #ffc107;
    border-color: #ffc107;
  }
  
  .timer-buttons .btn-danger {
    background-color: #dc3545;
    border-color: #dc3545;
  }
  
  .timer-buttons .btn-success:hover {
    background-color: #218838;
    border-color: #1e7e34;
  }
  
  .timer-buttons .btn-warning:hover {
    background-color: #e0a800;
    border-color: #d39e00;
  }
  
  .timer-buttons .btn-danger:hover {
    background-color: #c82333;
    border-color: #bd2130;
  }