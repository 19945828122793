.client-details {
    padding: 30px;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    color: #343a40;
    max-width: 1000px;
    margin: 20px auto;
  }
  
  .back-button {
    display: inline-flex;
    align-items: center;
    background-color: #f8f9fa;
    border: 2px solid #007bff;
    color: #007bff;
    padding: 8px 16px;
    border-radius: 4px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
    margin-bottom: 25px;
  }
  
  .back-button:hover {
    background-color: #007bff;
    color: #ffffff;
  }
  
  .client-details h2 {
    font-size: 2rem;
    margin-bottom: 20px;
    color: #007bff;
    text-align: center;
  }
  
  .client-details h3 {
    font-size: 1.5rem;
    margin-bottom: 15px;
    color: #6c757d;
    border-bottom: 2px solid #dee2e6;
    padding-bottom: 5px;
  }
  
  .client-info,
  .vehicles-info {
    margin-bottom: 25px;
  }
  
  .client-info p,
  .vehicles-info p {
    font-size: 1rem;
    line-height: 1.6;
    margin: 8px 0;
  }
  
  .client-info strong,
  .vehicles-info strong {
    color: #007bff;
  }
  
  .vehicles-info table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
  }
  
  .vehicles-info th,
  .vehicles-info td {
    padding: 12px 15px;
    text-align: left;
  }
  
  .vehicles-info th {
    background-color: #007bff;
    color: #ffffff;
    font-size: 1rem;
  }
  
  .vehicles-info tr:nth-child(even) {
    background-color: #f8f9fa;
  }
  
  .vehicles-info tr:hover {
    background-color: #ffffff;
  }
  
  .vehicles-info td {
    border-bottom: 1px solid #dee2e6;
  }
  
  /* Responsividade */
  @media (max-width: 768px) {
    .client-details {
      padding: 20px;
    }
  
    .client-details h2 {
      font-size: 1.75rem;
    }
  
    .client-details h3 {
      font-size: 1.25rem;
    }
  
    .vehicles-info th,
    .vehicles-info td {
      padding: 10px 12px;
    }
  
    .back-button {
      padding: 6px 12px;
      font-size: 0.9rem;
    }
  }