.progress-bar-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  
  .progress-step {
    text-align: center;
    flex: 1;
  }
  
  .progress-step.active .step-number {
    background-color: #3498db;
    color: white;
  }
  
  .step-number {
    width: 30px;
    height: 30px;
    line-height: 30px;
    margin: auto;
    border-radius: 50%;
    background-color: #ccc;
    color: black;
    font-weight: bold;
  }
  
  .progress-line {
    flex: 1;
    height: 2px;
    background-color: #ccc;
    margin: auto;
  }

  .budget-item .btn-link {
    border: none;
    background: none;
    cursor: pointer;
  }
  
  .budget-item .btn-link:hover {
    color: #dc3545; /* Cor de destaque ao passar o mouse */
  }
  
  .budget-item .row {
    align-items: center; /* Garante alinhamento vertical */
  }

  .budget-item {
    margin-top: 10px;
  }

  .flex{
  display: flex;
}
.space{
    margin-left: 10px;
  }
.budget-item .btn-link:disabled {
    color: #ccc; /* Cor cinza para indicar desabilitado */
    cursor: not-allowed;
}

.budget-item .btn-outline-primary {
    margin-top: 10px;
    font-size: 0.9rem;
}

.budget-item textarea {
    margin-top: 5px;
    font-size: 0.9rem;
    resize: none;
}

.review-budget {
    font-family: Arial, sans-serif;
  }
  
  .client-info,
  .vehicle-info,
  .totals-summary {
    margin-bottom: 20px;
    background-color: #f9f9f9;
  }
  
  .form-section-title {
    font-size: 1.25rem;
    font-weight: bold;
    margin-bottom: 15px;
  }
  
  .table {
    margin-top: 20px;
  }
  
  .table th,
  .table td {
    text-align: left;
    padding: 10px;
  }
  
  .table th {
    background-color: #f1f1f1;
  }
  
  .table td {
    background-color: #ffffff;
  }
  
  .totals-summary {
    background-color: #f4f4f4;
    font-size: 1.1rem;
  }
  
  .totals-summary p {
    margin: 5px 0;
  }

  .radio-group {
    display: flex;
    align-items: center;
    gap: 20px; /* Espaçamento entre os botões */
  }
  
  .radio-group label {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  
  .radio-group input {
    margin-right: 8px; /* Espaçamento entre o botão e o texto */
  }