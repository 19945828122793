/* CarDetails.css */

/* Variáveis de Cores (Opcional) */
:root {
    --primary-color: #007bff;
    --secondary-color: #6c757d;
    --background-color: #f8f9fa;
    --text-color: #343a40;
    --border-color: #dee2e6;
    --success-color: #28a745;
    --danger-color: #dc3545;
    --light-color: #ffffff;
    --font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  }
  
  /* Container Principal */
  .car-details {
    padding: 30px;
    background-color: var(--light-color);
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    font-family: var(--font-family);
    color: var(--text-color);
    max-width: 1000px;
    margin: 0 auto;
  }
  
  /* Botão de Voltar */
  .back-button {
    display: inline-flex;
    align-items: center;
    background-color: var(--background-color);
    border: 2px solid var(--primary-color);
    color: var(--primary-color);
    padding: 8px 16px;
    border-radius: 4px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
    margin-bottom: 25px;
  }
  
  .back-button:hover {
    background-color: var(--primary-color);
    color: var(--light-color);
  }
  
  /* Títulos das Seções */
  .car-details h2 {
    font-size: 2rem;
    margin-bottom: 20px;
    color: var(--primary-color);
    text-align: center;
  }
  
  .car-details h3 {
    font-size: 1.5rem;
    margin-bottom: 15px;
    color: var(--secondary-color);
    border-bottom: 2px solid var(--border-color);
    padding-bottom: 5px;
  }
  
  /* Informações do Cliente e Veículo */
  .client-info,
  .vehicle-info {
    margin-bottom: 25px;
  }
  
  .client-info p,
  .vehicle-info p {
    font-size: 1rem;
    line-height: 1.6;
    margin: 8px 0;
  }
  
  .client-info strong,
  .vehicle-info strong {
    color: var(--primary-color);
  }
  
  /* Informações dos Tickets */
  .tickets-info {
    margin-bottom: 25px;
  }
  
  .tickets-info table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
  }
  
  .tickets-info th,
  .tickets-info td {
    padding: 12px 15px;
    text-align: left;
  }
  
  .tickets-info th {
    background-color: var(--primary-color);
    color: var(--light-color);
    font-size: 1rem;
  }
  
  .tickets-info tr:nth-child(even) {
    background-color: var(--background-color);
  }
  
  .tickets-info tr:hover {
    background-color: var(--light-color);
  }
  
  .tickets-info td {
    border-bottom: 1px solid var(--border-color);
  }
  
  /* Responsividade */
  @media (max-width: 768px) {
    .car-details {
      padding: 20px;
    }
  
    .car-details h2 {
      font-size: 1.75rem;
    }
  
    .car-details h3 {
      font-size: 1.25rem;
    }
  
    .tickets-info th,
    .tickets-info td {
      padding: 10px 12px;
    }
  
    .back-button {
      padding: 6px 12px;
      font-size: 0.9rem;
    }
  }
  
  /* Botões Adicionais (Opcional) */
  .action-buttons {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    margin-top: 20px;
  }
  
  .action-buttons .btn {
    padding: 10px 20px;
    font-size: 1rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .action-buttons .btn.edit {
    background-color: var(--secondary-color);
    color: var(--light-color);
  }
  
  .action-buttons .btn.edit:hover {
    background-color: darken(var(--secondary-color), 10%);
  }
  
  .action-buttons .btn.delete {
    background-color: var(--danger-color);
    color: var(--light-color);
  }
  
  .action-buttons .btn.delete:hover {
    background-color: darken(var(--danger-color), 10%);
  }