/* src/pages/CalendarModalContent.css */

/* Overlay do Modal */
.custom-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.6); /* Fundo semi-transparente */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9998; /* Garante que o modal fique acima de outros elementos */
  }
  
  /* Conteúdo do Modal */
  .custom-modal {
    background: #ffffff;
    padding: 30px;
    max-width: 1200px;
    width: 90%;
    border-radius: 10px;
    position: relative;
    max-height: 90vh;
    overflow-y: auto;
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.2); /* Sombra para destaque */
    transition: all 0.3s ease-in-out;
  }
  
  /* Cabeçalho do Modal */
  .custom-modal .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 25px;
  }
  
  .custom-modal .modal-header h2 {
    margin: 0;
    font-size: 1.8rem;
    color: #333333;
  }
  
  .custom-modal .close-button {
    background: transparent;
    border: none;
    font-size: 1.8rem;
    cursor: pointer;
    color: #888888;
    transition: color 0.2s ease-in-out;
  }
  
  .custom-modal .close-button:hover {
    color: #000000;
  }
  
  /* Layout Flexbox para os Campos */
  .form-row {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin-bottom: 15px;
  }
  
  .form-group {
    flex: 1;
    min-width: 250px;
    display: flex;
    flex-direction: column;
  }
  
  .form-group label {
    font-weight: 600;
    color: #555555;
    margin-bottom: 5px;
  }
  
  .form-group input[type="text"],
  .form-group input[type="number"],
  .form-group select,
  .form-group textarea {
    padding: 10px 15px;
    border: 1px solid #cccccc;
    border-radius: 6px;
    font-size: 1rem;
    transition: border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
  }
  
  .form-group input:focus,
  .form-group select:focus,
  .form-group textarea:focus {
    border-color: #007bff;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
    outline: none;
  }
  
  .form-group textarea {
    resize: vertical;
    min-height: 100px;
  }
  
  /* Estilização do Checkbox */
  .checkbox-label {
    display: flex;
    align-items: center;
    font-weight: 600;
    color: #555555;
  }
  
  .checkbox-label input {
    margin-right: 10px;
    transform: scale(1.2);
  }
  
  /* Estilização do Campo de Telefone */
  .phone-input {
    display: flex;
    align-items: center;
  }

  .phone-display {
    padding: 10px 15px;
    border: 1px solid #cccccc;
    border-radius: 6px;
    background-color: #f9f9f9;
    font-size: 1rem;
    color: #333333;
  }
  
  .country-code-select {
    width: 100%;
    margin-right: 10px;
  }
  
  /* Botões de Ação */
  .form-actions {
    display: flex;
    justify-content: flex-end;
    gap: 15px;
    margin-top: 20px;
  }
  
  .btn {
    padding: 10px 20px;
    font-size: 1rem;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out, transform 0.1s ease-in-out;
  }
  
  .btn-primary {
    background-color: #007bff;
    color: #ffffff;
  }
  
  .btn-primary:hover {
    background-color: #0056b3;
  }
  
  .btn-secondary {
    background-color: #6c757d;
    color: #ffffff;
  }
  
  .btn-secondary:hover {
    background-color: #5a6268;
  }
  
  .btn:active {
    transform: scale(0.98);
  }
  
  /* Responsividade */
  @media (max-width: 768px) {
    .form-row {
      flex-direction: column;
    }
  
    .country-code-select {
      width: 80px;
    }
  
    .custom-modal {
      padding: 20px;
      max-width: 90%;
    }
  
    .custom-modal .modal-header h2 {
      font-size: 1.5rem;
    }
  
    .custom-modal .close-button {
      font-size: 1.5rem;
    }
  }
  
  /* Estilização do DatePicker */
  .custom-datepicker {
    width: 100%;
    padding: 10px 15px;
    border: 1px solid #cccccc;
    border-radius: 6px;
    font-size: 1rem;
    transition: border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
  }
  
  .custom-datepicker:focus {
    border-color: #007bff;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
    outline: none;
  }