.pagination {
    margin-top: 20px;
  }
  
  .pagination .pagination-btn {
    margin: 0 2px;
  }
  
  .pagination .pagination-btn.active {
    background-color: #0d6efd;
    border-color: #0d6efd;
    color: white;
  }
  
  .pagination .pagination-btn:hover:not(.active) {
    background-color: #e2e6ea;
    color: #0d6efd;
  }
  
  .pagination .btn:disabled {
    opacity: 0.65;
    cursor: not-allowed;
  }