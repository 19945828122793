/* Adicione ao seu arquivo CSS, por exemplo, EditBudget.css */
.Modal {
    position: absolute;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    background: white;
    padding: 20px;
    border-radius: 8px;
    max-width: 500px;
    width: 90%;
  }
  
  .Overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75);
    z-index: 1000;
  }
  
  .close-button {
    background: none;
    border: none;
    font-size: 1.5rem;
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }

/* Seção de Upload de Arquivos */
.upload-section {
  margin-top: 20px;
}

.upload-section h3 {
  margin-bottom: 15px;
}

.upload-section ul {
  list-style-type: none;
  padding-left: 0;
}

.upload-section li {
  margin-bottom: 8px;
}

.upload-section a {
  color: #007bff;
  text-decoration: none;
}

.upload-section a:hover {
  text-decoration: underline;
}

/* frontend/src/components/EditBudget.css */

.edit-budget-container {
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.upload-section {
  margin-top: 20px;
}

.btn-upload {
  margin-top: 10px;
  padding: 8px 16px;
  background-color: #6c757d; /* Cinza escuro */
  color: #ffffff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.btn-upload:disabled {
  background-color: #adb5bd;
  cursor: not-allowed;
}

.files-list {
  margin-top: 30px;
}

.files-list ul {
  list-style-type: none;
  padding: 0;
}

.file-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 12px;
  border-bottom: 1px solid #e6e6e6;
}

.file-item a {
  text-decoration: none;
  color: #007bff;
}

.file-item a:hover {
  text-decoration: underline;
}

.delete-button {
  background: none;
  border: none;
  color: #6c757d; /* Cinza escuro */
  cursor: pointer;
  font-size: 1rem;
}

.delete-button:hover {
  color: #dc3545; /* Vermelho para indicar ação de deleção */
}