/* src/pages/Dashboard.css */

.dashboard-container {
  padding: 20px;
}

.dashboard-content {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

.widget {
  flex: 1;
  min-width: 200px;
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.widget h2 {
  margin-bottom: 10px;
}

.card-body span {
  font-size: 14px;
  font-weight: bold;
}

.card-body span[style*="green"] {
  color: #28a745;
}

.card-body span[style*="red"] {
  color: #dc3545;
}

.ticket-card {
  border-radius: 5px;
  min-height: 260px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

/* Opcional: Ajustar o tamanho mínimo apenas para telas maiores */
@media (min-width: 768px) {
  .ticket-card {
    min-width: 160px;
  }
}

/* Cores Vibrantes */
.ticket-card.red {
  background-color: #FF6B6B; /* vermelho bem vivo, estilo "salmon red" */
  color: #ffffff;
}

.ticket-card.yellow {
  background-color: #FFE066; /* amarelo mostarda claro mais vivo */
  color: #333333; 
}

.ticket-card.green {
  background-color: #1DD1A1; /* verde turquesa vivo */
  color: #ffffff;
}

@media (max-width: 768px) {
  .content-area {
    padding: 60px 15px 15px 15px;
  }

}

@media (max-width: 480px) {
  .content-area {
    padding: 50px 10px 10px 10px;
  }
}

.confirm-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  transform: translate(-50%, -50%);
  background: #fff;
  padding: 2rem;
  border-radius: 8px;
  max-width: 500px;
  width: 90%;
  box-shadow: 0 5px 15px rgba(0,0,0,0.3);
}

.confimr-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.5);
}