

/*
Template Name: Syndron Admin
Author: codervent
Email: codervent@gmail.com
File: app.css
*/


/*  
  - Google Font
  - General
  - Sidebar
  - Header
  - Metismenu
  - Extra CSS
  - Invoice Page
  - Chat box
  - Email box
  - Compose Mail
  - Navs and Tabs
  - Background Colors
  - Authentication
  - Buttons 
  - Pricing Table
  - Forms
  - Responsive
*/



/* General */


body {
font-size: 14px;
color: #4c5258;
letter-spacing: .5px;
overflow-x:hidden;
background-color: #f4f4f4;
font-family: Roboto, sans-serif
}


a {


	text-decoration: none;


}


.wrapper {


	width: 100%;


	position: relative;


}

.mobile-topbar-header {

	display: none;
	
}

/* Sidebar */


.topbar-logo-header{
	width: auto;
    display: flex;
    align-items: center;
    padding: 0 30px 0 0;
    height: 60px;
    border-right: 1px solid #f0f0f0;
}


.logo-icon {


	width: 30px;


}

.logo-icon-2 {
	width: 35px;
	
    margin-left: 10px;
}


.logo-text {


	font-size: 22px;


	margin-left: 10px;


	margin-bottom: 0;


	letter-spacing: 1px;


	color: #0d6efd;


}


.toggle-icon {

	font-size: 26px;
	

	color: #0d6efd;
	

    cursor: pointer;


}


/* Header */


.topbar {


	position: fixed;


	top: 0;


	left: 0;


	right: 0;


	height: 60px;


	background: #ffffff;


	border-bottom: 1px solid #f0f0f0;


	z-index: 10;


}


.topbar .navbar {


	width: 100%;


	height: 60px;


	padding-left: 30px;


	padding-right: 30px;


}


.page-wrapper {


	height: 100%;


	margin-top: 0px;


	margin-bottom: 30px;


	margin-left: 0px;


}

@media screen and (min-width: 1400px){

	.page-wrapper {

		padding-left: 20px;

		padding-right: 20px;

	}

}


.page-content {


	padding: 1.5rem;


}


.page-footer {


	background: #ffffff;


	left: 0px;


	right: 0;


	bottom: 0;


	position: fixed;


	text-align: center;


	padding: 7px;


	font-size: 14px;


	border-top: 1px solid #e4e4e4;


	z-index: 3;


}


/*Metismenu*/

.nav-container {

	position: fixed;

	top: 60px;

	right: 0;

	left: 0;

	z-index: 9;

	border-bottom: 1px solid #e4e4e4;
	
	box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .075)!important;

	background-clip: border-box;

	background: #ffffff;

	width: 100%;

	height: 60px;

	padding: 0px 30px;

	display: flex;

	align-items: center;

	justify-content: center;

}

.topbar-nav {

	background: transparent;

	width: 100%;

}

.topbar-nav ul {

	padding: 0;

	margin: 0;

	list-style: none;

	background: transparent;

}

.topbar-nav .metismenu {

	display: -webkit-box;

	display: -ms-flexbox;

	display: flex;

	-webkit-box-orient: vertical;

	-webkit-box-direction: normal;

	-ms-flex-direction: column;

	flex-direction: column;

}

.topbar-nav .metismenu > li {

	-webkit-box-flex: 1;

	-ms-flex: 1 1 0%;

	flex: 1 1 0%;

	display: -webkit-box;

	display: -ms-flexbox;

	display: flex;

	-webkit-box-orient: vertical;

	-webkit-box-direction: normal;

	-ms-flex-direction: column;

	flex-direction: column;

	position: relative;

}

.topbar-nav .metismenu a {

	position: relative;

	display: flex;

	align-items: center;

	justify-content: left;

	padding: 10px 15px;

	color: #5f5f5f;

	outline-width: 0;

	transition: all .3s ease-out;

}

.topbar-nav .metismenu ul a {

	padding: 8px 15px 8px 15px;

	font-size: 15px;

	border: 0;

}

.topbar-nav .metismenu ul a i {

	margin-right: 10px;

}

.topbar-nav .metismenu a .parent-icon {

	font-size: 24px;

	line-height: 1;

}

.topbar-nav .metismenu a .menu-title {

	margin-left: 10px;

}

.topbar-nav .metismenu a:hover,

.topbar-nav .metismenu a:focus,

.topbar-nav .metismenu a:active {

	color: #ffffff;

	text-decoration: none;

	background: #0d6efd;

}

@media (min-width: 1281px) {

	.topbar-nav .metismenu {

		-webkit-box-orient: horizontal;

		-webkit-box-direction: normal;

		-ms-flex-direction: row;

		flex-direction: row;

	}

	.topbar-nav .metismenu > li {

		-webkit-box-orient: vertical;

		-webkit-box-direction: normal;

		-ms-flex-direction: column;

		flex-direction: column;

	}

	.topbar-nav .metismenu > li > ul {

		position: absolute;

		top: 100%;

		min-width: 100%;

		z-index: 1001;

		background: white;

		box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, .15);

	}

	.topbar-nav.is-hoverable .metismenu > li > ul {

		height: auto !important;

	}

	.topbar-nav.is-hoverable .metismenu > li:hover > ul {

		display: block;

	}

	.topbar-nav.is-hoverable .metismenu > li:hover > a.has-arrow:after {

		-webkit-transform: rotate(-135deg);

		transform: rotate(-135deg);

	}

}


@media screen and (min-width: 1025px) {



	.email-toggle-btn {


		display: none !important;


	}


	.chat-toggle-btn {


		display: none !important;


	}


}


/* Extra CSS */


.back-to-top {


	display: none;


	width: 40px;


	height: 40px;


	line-height: 40px;


	text-align: center;


	font-size: 26px;


	color: white;


	position: fixed;


	border-radius: 10px;


	bottom: 20px;


	right: 12px;


	background-color: #0d6efd;


	z-index: 5;


}


.back-to-top:hover {


	color: white;


	background-color: #000;


	transition: all .5s;


}


.breadcrumb-title {


	font-size: 20px;


	border-right: 1.5px solid #aaa4a4;


}


.page-breadcrumb .breadcrumb li.breadcrumb-item {


	font-size: 16px;


}


.page-breadcrumb .breadcrumb-item+.breadcrumb-item::before {


	display: inline-block;


	padding-right: .5rem;


	color: #6c757d;


	font-family: 'LineIcons';


	content: "\ea5c";


}


.icon-badge {


	width: 45px;


	height: 45px;


	background: #f2f2f2;


	display: flex;


	align-items: center;


	justify-content: center;


	border-radius: 50%;


}


.widgets-icons {


	width: 50px;


	height: 50px;


	display: flex;


	align-items: center;


	justify-content: center;


	background-color: #ededed;


	font-size: 26px;


	border-radius: 10px;


}


#geographic-map {


	width: 100%;


	height: 440px;


}


.product-img {


	width: 60px;


	height: 60px;


	background-color: white;


	display: flex;


	align-items: center;


	justify-content: center;


	border-radius: 10px;


	border: 1px solid #e6e6e6;


}


.product-img img {


	width: 60px;


	height: 60px;


	padding: 6px;


}


.product-list {


	position: relative;


	height: 380px;


}


.dashboard-top-countries {


	position: relative;


	height: 360px;


}


.customers-list {


	position: relative;


	height: 450px;


}


.product-list .row {


	background-color: #f8f9fa;


	-webkit-transition: all 0.2s;


	-o-transition: all 0.2s;


	transition: all 0.2s;


}


@media (min-width: 992px) {


	.product-list .row:hover {


		background-color: #fff;


		margin-top: -.25rem;


		margin-bottom: .25rem;


		-webkit-box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.2);


		box-shadow: 0 0.25rem 0.5rem 0 rgba(0, 0, 0, 0.2);


	}


}


.recent-product-img {


	width: 40px;


	height: 40px;


	background-color: #fbfbfb;


	display: flex;


	align-items: center;


	justify-content: center;


	border-radius: 10px;


	border: 1px solid #e6e6e6;


}


.recent-product-img img {


	width: 40px;


	height: 40px;


	padding: 6px;


}


.theme-icons {


	background-color: #FFF;


}


.lead-table .table {


	border-collapse: separate;


	border-spacing: 0 10px;


}


.fm-menu .list-group a {


	font-size: 16px;


	color: #5f5f5f;


	display: flex;


	align-items: center;


}


.fm-menu .list-group a i {


	font-size: 23px;


}


.fm-menu .list-group a:hover {


	background: #0d6efd;


	color: #ffffff;


	transition: all .2s ease-out;


}


.fm-file-box {


	font-size: 25px;


	background: #e9ecef;


	width: 44px;


	height: 44px;


	display: flex;


	align-items: center;


	justify-content: center;


	border-radius: .25rem;


}


.fm-icon-box {


	font-size: 32px;


	background: #ffffff;


	width: 52px;


	height: 52px;


	display: flex;


	align-items: center;


	justify-content: center;


	border-radius: .25rem;


}


.user-plus {


	width: 33px;


	height: 33px;


	margin-left: -14px;


	line-height: 33px;


	background: #ffffff;


	border-radius: 50%;


	text-align: center;


	font-size: 22px;


	cursor: pointer;


	border: 1px dotted #a9b2bb;


	color: #404142;


}


.user-groups img {


	margin-left: -14px;


	border: 1px solid #e4e4e4;


	padding: 2px;


	cursor: pointer;


}


.contacts-social a {


	font-size: 16px;


	width: 36px;


	height: 36px;


	line-height: 36px;


	background: #ffffff;


	border: 1px solid #eeecec;


	text-align: center;


	border-radius: 50%;


	color: #2b2a2a;


}


.customers-contacts a {


	font-size: 16px;


	width: 34px;


	height: 34px;


	display: flex;


	align-items: center;


	justify-content: center;


	background: #ffffff;


	border: 1px solid #eeecec;


	text-align: center;


	border-radius: 50%;


	color: #2b2a2a;


}


.order-actions a {


	font-size: 18px;


	width: 34px;


	height: 34px;


	display: flex;


	align-items: center;


	justify-content: center;


	background: #f1f1f1;


	border: 1px solid #eeecec;


	text-align: center;


	border-radius: 20%;


	color: #2b2a2a;


}


.customers-list .customers-list-item {


	-webkit-transition: all 0.2s;


	-o-transition: all 0.2s;


	transition: all 0.2s;


}


@media (min-width: 992px) {


	.customers-list .customers-list-item:hover {


		background-color: #f8f9fa;


		border-radius: 10px;


		margin-top: -.25rem;


		margin-bottom: .25rem;


		-webkit-box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.2);


		box-shadow: 0 0.25rem 0.5rem 0 rgba(0, 0, 0, 0.2);


	}


}


.right-15 {


	right: 15px!important;


}


.font-13 {


	font-size: 13px;


}


.font-14 {


	font-size: 14px;


}


.font-18 {


	font-size: 18px;


}


.font-20 {


	font-size: 20px;


}


.font-22 {


	font-size: 22px;


}


.font-24 {


	font-size: 24px;


}


.font-30 {


	font-size: 30px;


}


.font-35 {


	font-size: 35px;


}


.font-50 {


	font-size: 50px;


}


.font-60 {


	font-size: 60px;


}


.radius-30 {


	border-radius: 30px;


}


.radius-10 {


	border-radius: 10px;


}


.radius-15 {


	border-radius: 15px;


}


.cursor-pointer {


	cursor: pointer;


}


.chip {


	display: inline-block;


	height: 32px;


	padding: 0 12px;


	margin-right: 1rem;


	margin-bottom: 1rem;


	font-size: 14px;


	font-weight: 500;


	line-height: 32px;


	color: rgba(0, 0, 0, 0.70);


	cursor: pointer;


	background-color: #f1f1f1;


	border: 1px solid rgba(0, 0, 0, 0.15);


	border-radius: 16px;


	-webkit-transition: all .3s linear;


	transition: all .3s linear;


	box-shadow: none;


}


.chip img {


	float: left;


	width: 32px;


	height: 32px;


	margin: 0 8px 0 -12px;


	border-radius: 50%;


}


.chip .closebtn {


	padding-left: 10px;


	font-weight: bold;


	float: right;


	font-size: 16px;


	cursor: pointer;


}


.chip.chip-md {


	height: 42px;


	line-height: 42px;


	border-radius: 21px;


}


.chip.chip-md img {


	height: 42px;


	width: 42px;


}


/*Invoice Page*/


#invoice {


	padding: 0px;


}


.invoice {


	position: relative;


	background-color: #FFF;


	min-height: 680px;


	padding: 15px


}


.invoice header {


	padding: 10px 0;


	margin-bottom: 20px;


	border-bottom: 1px solid #0d6efd


}


.invoice .company-details {


	text-align: right


}


.invoice .company-details .name {


	margin-top: 0;


	margin-bottom: 0


}


.invoice .contacts {


	margin-bottom: 20px


}


.invoice .invoice-to {


	text-align: left


}


.invoice .invoice-to .to {


	margin-top: 0;


	margin-bottom: 0


}


.invoice .invoice-details {


	text-align: right


}


.invoice .invoice-details .invoice-id {


	margin-top: 0;


	color: #0d6efd


}


.invoice main {


	padding-bottom: 50px


}


.invoice main .thanks {


	margin-top: -100px;


	font-size: 2em;


	margin-bottom: 50px


}


.invoice main .notices {


	padding-left: 6px;


	border-left: 6px solid #0d6efd;


	background: #e7f2ff;


	padding: 10px;


}


.invoice main .notices .notice {


	font-size: 1.2em


}


.invoice table {


	width: 100%;


	border-collapse: collapse;


	border-spacing: 0;


	margin-bottom: 20px


}


.invoice table td,


.invoice table th {


	padding: 15px;


	background: #eee;


	border-bottom: 1px solid #fff


}


.invoice table th {


	white-space: nowrap;


	font-weight: 400;


	font-size: 16px


}


.invoice table td h3 {


	margin: 0;


	font-weight: 400;


	color: #0d6efd;


	font-size: 1.2em


}


.invoice table .qty,


.invoice table .total,


.invoice table .unit {


	text-align: right;


	font-size: 1.2em


}


.invoice table .no {


	color: #fff;


	font-size: 1.6em;


	background: #0d6efd


}


.invoice table .unit {


	background: #ddd


}


.invoice table .total {


	background: #0d6efd;


	color: #fff


}


.invoice table tbody tr:last-child td {


	border: none


}


.invoice table tfoot td {


	background: 0 0;


	border-bottom: none;


	white-space: nowrap;


	text-align: right;


	padding: 10px 20px;


	font-size: 1.2em;


	border-top: 1px solid #aaa


}


.invoice table tfoot tr:first-child td {


	border-top: none


}


.invoice table tfoot tr:last-child td {


	color: #0d6efd;


	font-size: 1.4em;


	border-top: 1px solid #0d6efd


}


.invoice table tfoot tr td:first-child {


	border: none


}


.invoice footer {


	width: 100%;


	text-align: center;


	color: #777;


	border-top: 1px solid #aaa;


	padding: 8px 0


}


@media print {


	.invoice {


		font-size: 11px!important;


		overflow: hidden!important


	}


	.invoice footer {


		position: absolute;


		bottom: 10px;


		page-break-after: always


	}


	.invoice>div:last-child {


		page-break-before: always


	}


}


.main-row {


	height: 100vh;


}


.main-col {


	max-width: 500px;


	min-height: 300px;


}


.todo-done {


	text-decoration: line-through;


}


/*Chat box*/


.chat-wrapper {


	width: auto;


	height: 600px;


	border-radius: 0.25rem;


	position: relative;


	background: #ffffff;


	box-shadow: 0 0.1rem 0.7rem rgba(0, 0, 0, .10);


}


.chat-sidebar {


	width: 340px;


	height: 100%;


	position: absolute;


	background: #ffffff;


	left: 0;


	top: 0;


	bottom: 0;


	z-index: 2;


	overflow: hidden;


	border-right: 1px solid rgba(0, 0, 0, .125);


	border-top-left-radius: 0.25rem;


	border-bottom-left-radius: 0.25rem;


}


.chat-sidebar-header {


	width: auto;


	height: auto;


	position: relative;


	background: #ffffff;


	border-bottom: 1px solid rgba(0, 0, 0, .125);


	border-right: 0px solid rgba(0, 0, 0, .125);


	border-top-left-radius: 0.25rem;


	padding: 15px;


}


.chat-sidebar-content {


	padding: 0px;


}


.chat-user-online {


	position: relative;


}


.chat-sidebar-header .chat-user-online:before {


	content: '';


	position: absolute;


	bottom: 7px;


	left: 40px;


	width: 8px;


	height: 8px;


	border-radius: 50%;


	box-shadow: 0 0 0 2px #fff;


	background: #16e15e;


}


.chat-list .chat-user-online:before {


	content: '';


	position: absolute;


	bottom: 7px;


	left: 36px;


	width: 8px;


	height: 8px;


	border-radius: 50%;


	box-shadow: 0 0 0 2px #fff;


	background: #16e15e;


}


.chat-content {


	margin-left: 340px;


	padding: 85px 15px 15px 15px;


}


.chat-header {


	position: absolute;


	height: 70px;


	left: 340px;


	right: 0;


	top: 0;


	padding: 15px;


	background: #ffffff;


	border-bottom: 1px solid rgba(0, 0, 0, .125);


	border-top-right-radius: 0.25rem;


	z-index: 1;


}


.chat-footer {


	position: absolute;


	height: 70px;


	left: 340px;


	right: 0;


	bottom: 0;


	padding: 15px;


	background: #f8f9fa;


	border-top: 1px solid rgba(0, 0, 0, .125);


	border-bottom-right-radius: 0.25rem;


}


.chat-footer-menu a {


	display: inline-block;


	width: 40px;


	height: 40px;


	line-height: 40px;


	font-size: 18px;


	color: #6c757d;


	text-align: center;


	border-radius: 50%;


	margin: 3px;


	background-color: white;


	border: 1px solid rgb(0 0 0 / 15%);


}


.chat-tab-menu li a.nav-link {


	padding: .3rem 0.2rem;


	line-height: 1.2;


	color: #4a4b4c;


}


.chat-tab-menu .nav-pills .nav-link.active,


.chat-tab-menu .nav-pills .show>.nav-link {


	color: #0d6efd;


	background-color: rgb(0 123 255 / 0%);


}


.chat-title {


	font-size: 14px;


	color: #272b2f;


}


.chat-msg {


	font-size: 13px;


	color: #6c757d;


}


.chat-time {


	font-size: 13px;

	margin-left: 5px;

	color: #6c757d;


}


.chat-list {


	position: relative;


	height: 300px;


}


.chat-list .list-group-item {


	border: 1px solid rgb(0 0 0 / 0%);


	background-color: transparent;


}


.chat-list .list-group-item:hover {


	border: 1px solid rgb(0 0 0 / 0%);


	background-color: rgb(13 110 253 / 0.12)


}


.chat-list .list-group-item.active {


	background-color: rgb(13 110 253 / 0.12)


}


.chart-online {


	color: #16e15e;


}


.chat-top-header-menu a {


	display: inline-block;


	width: 40px;


	height: 40px;


	line-height: 40px;


	font-size: 18px;


	color: #6c757d;


	text-align: center;


	border-radius: 50%;


	margin: 3px;


	background-color: white;


	border: 1px solid rgb(0 0 0 / 15%);


}


.chat-content {


	position: relative;


	width: auto;


	height: 520px;


}


.chat-content-leftside .chat-left-msg {


	width: fit-content;


	background-color: #eff2f5;


	padding: 0.80rem;


	border-radius: 12px;


	max-width: 480px;


	text-align: left;


	border-top-left-radius: 0;


}


.chat-content-rightside .chat-right-msg {


	width: fit-content;


	background-color: #dcedff;


	padding: 0.80rem;


	border-radius: 12px;


	float: right;


	max-width: 480px;


	text-align: left;


	border-bottom-right-radius: 0;


}


.chat-toggle-btn {


	width: 40px;


	height: 40px;


	line-height: 40px;


	margin-right: 15px;


	text-align: center;


	font-size: 24px;


	color: #6c757d;


	border-radius: 50%;


	cursor: pointer;


	background-color: white;


	border: 1px solid rgb(0 0 0 / 15%);


}


/*Email box*/


.email-wrapper {


	width: auto;


	height: 600px;


	overflow: hidden;


	border-radius: 0.25rem;


	position: relative;


	background: #ffffff;


	box-shadow: 0 0.1rem 0.7rem rgba(0, 0, 0, .10);


}


.email-sidebar {


	width: 250px;


	height: 100%;


	position: absolute;


	background: #fff;


	left: 0;


	top: 0;


	bottom: 0;


	z-index: 2;


	overflow: hidden;


	border-right: 1px solid rgba(0, 0, 0, .125);


	border-top-left-radius: 0.25rem;


	border-bottom-left-radius: 0.25rem;


}


.email-sidebar-header {


	width: auto;


	height: auto;


	position: relative;


	background: #ffffff;


	border-bottom: 1px solid rgba(0, 0, 0, .125);


	border-right: 0px solid rgba(0, 0, 0, .125);


	border-top-left-radius: 0.25rem;


	padding: 15px;


}


.email-navigation {


	position: relative;


	padding: 0px;


	height: 345px;


	border-bottom: 1px solid rgba(0, 0, 0, .125);


}


.email-header {


	position: absolute;


	height: 70px;


	left: 250px;


	right: 0;


	top: 0;


	padding: 15px;


	background: #ffffff;


	border-bottom: 1px solid rgba(0, 0, 0, .125);


	border-top-right-radius: 0.25rem;


	z-index: 1;


}


.email-content {


	position: absolute;


	left: 0;


	right: 0;


	width: auto;


	top: 70px;


	height: auto;


	margin-left: 250px;


	padding: 0;


	background: #ffffff;


	border-top-left-radius: 0.25rem;


	border-top-right-radius: 0.25rem;


}


.email-navigation a.list-group-item {


	color: #404142;


	padding: .35rem 1.25rem;


	background-color: white;


	border-bottom: 1px solid rgb(0 0 0 / 0%);


	transition: all .3s ease-out;


}


.email-navigation a.list-group-item:hover {


	background-color: rgb(13 110 253 / 0.12);


}


.email-navigation a.list-group-item.active {


	color: #0b5ed7;


	font-weight: 600;


	background-color: rgb(13 110 253 / 0.12)


}


.email-meeting {


	position: absolute;


	left: 0;


	right: 0;


	bottom: 0;


}


.email-meeting a.list-group-item {


	color: #404142;


	padding: .35rem 1.25rem;


	background-color: white;


	border-bottom: 1px solid rgb(0 0 0 / 0%);


}


.email-meeting a.list-group-item:hover {


	background-color: rgb(0 123 255 / 15%);


	transition: all .3s ease-out;


}


.email-hangout .chat-user-online:before {


	content: '';


	position: absolute;


	bottom: 8px;


	left: 45px;


	width: 8px;


	height: 8px;


	border-radius: 50%;


	box-shadow: 0 0 0 2px #fff;


	background: #16e15e;


}


.email-toggle-btn {


	width: auto;


	height: auto;


	margin-right: 10px;


	text-align: center;


	font-size: 24px;


	color: #404142;


	border-radius: 0;


	cursor: pointer;


	background-color: white;


	border: 0px solid rgb(0 0 0 / 15%);


}


.email-actions {


	width: 230px;


	;


}


.email-time {


	font-size: 13px;


	color: #6c757d;


}


.email-list div.email-message {


	background: #ffffff;


	border-bottom: 1px solid rgb(0 0 0 / 8%);


	color: #383a3c;


}


.email-list div.email-message:hover {


	transition: all .2s ease-out;


	background-color: #eceef1;


}


.email-list {


	position: relative;


	height: 530px;


}


.email-star {


	color: #6c757d;


}


.email-read-box {


	position: relative;


	height: 530px;


}


/*Compose Mail*/


.compose-mail-popup {


	width: 42%;


	position: fixed;


	bottom: -30px;


	right: 30px;


	z-index: 15;


	display: none;


}


.compose-mail-toggled {


	display: block;


}


.compose-mail-title {


	font-size: 16px;


}


.compose-mail-close {


	width: 25px;


	height: 25px;


	line-height: 25px;


	text-align: center;


	font-size: 14px;


	border-radius: 2px;


	background-color: rgb(255 255 255 / 0%);


}


.compose-mail-close:hover {


	background-color: rgb(255 255 255 / 20%);


}


/* Navs and Tabs */


.nav-primary.nav-tabs .nav-link.active {


	color: #0d6efd;


	border-color: #0d6efd #0d6efd #fff;


}


.nav-danger.nav-tabs .nav-link.active {


	color: #f41127;


	border-color: #f41127 #f41127 #fff;


}


.nav-success.nav-tabs .nav-link.active {


	color: #17a00e;


	border-color: #17a00e #17a00e #fff;


}


.nav-warning.nav-tabs .nav-link.active {


	color: #ffc107;


	border-color: #ffc107 #ffc107 #fff;


}


.nav-pills-danger.nav-pills .nav-link.active {


	color: #fff;


	background-color: #f41127;


}


.nav-pills-success.nav-pills .nav-link.active {


	color: #fff;


	background-color: #17a00e;


}


.nav-pills-warning.nav-pills .nav-link.active {


	color: #000;


	background-color: #ffc107;


}


.nav-search input.form-control {


	background-color: rgb(255 255 255 / 20%);


	border: 1px solid rgb(255 255 255 / 45%);


	color: #fff;


}


.nav-search button[type='submit'] {


	background-color: rgb(255 255 255 / 20%);


	border: 1px solid rgb(255 255 255 / 32%);


	color: #fff;


}


.nav-search input.form-control::placeholder {


	opacity: 0.5 !important;


	color: #fff !important;


}


.nav-search input.form-control::-ms-input-placeholder {


	color: #fff !important;


}


.round-pagination.pagination .page-item:first-child .page-link {


	border-top-left-radius: 30px;


	border-bottom-left-radius: 30px;


}


.round-pagination.pagination .page-item:last-child .page-link {


	border-top-right-radius: 30px;


	border-bottom-right-radius: 30px;


}


/* Background Colors */


.bg-light-primary {


	background-color: rgb(13 110 253 / 0.11) !important;


}


.bg-light-success {


	background-color: rgb(23 160 14 / 0.11)!important;


}


.bg-light-danger {


	background-color: rgb(244 17 39 / 0.11)!important;


}


.bg-light-warning {


	background-color: rgb(255 193 7 / 0.11)!important;


}


.bg-light-info {


	background-color: rgb(13 202 240 / 18%)!important;


}


.bg-gradient-cosmic {


	background: linear-gradient(to right, rgb(142, 45, 226), rgb(74, 0, 224)) !important;


}


.bg-gradient-burning {


	background: linear-gradient(to right, rgb(255, 65, 108), rgb(255, 75, 43))!important;


}


.bg-gradient-lush {


	background: linear-gradient(to right, rgb(86, 171, 47), rgb(168, 224, 99))!important;


}


.bg-gradient-kyoto {


	background: linear-gradient(to right, rgb(247, 151, 30), rgb(255, 210, 0))!important;


}


.bg-gradient-blues {


	background: linear-gradient(to right, rgb(86, 204, 242), rgb(47, 128, 237))!important;


}


.bg-gradient-moonlit {


	background: linear-gradient(to right, rgb(15, 32, 39), rgb(32, 58, 67), rgb(44, 83, 100))!important;


}


.split-bg-primary {


	background-color: #0c62e0;


	border-color: #0c62e0;


}


.split-bg-secondary {


	background-color: #515a62;


	border-color: #515a62;


}


.split-bg-success {


	background-color: #128e0a;


	border-color: #128e0a;


}


.split-bg-info {


	background-color: #0bb2d3;


	border-color: #0bb2d3;


}


.split-bg-warning {


	background-color: #e4ad07;


	border-color: #e4ad07;


}


.split-bg-danger {


	background-color: #e20e22;


	border-color: #e20e22;


}


.bg-facebook {


	background-color: #3b5998!important;


}


.bg-twitter {


	background-color: #55acee!important;


}


.bg-google {


	background-color: #e52d27!important;


}


.bg-linkedin {


	background-color: #0976b4!important;


}


/* Authentication */


.section-authentication-signin {


	height: 100vh;


}


.authentication-forgot {


	height: 100vh;


	padding: 0 1rem;


}


.authentication-reset-password {


	height: 100vh;


	padding: 0 1rem;


}


.authentication-lock-screen {


	height: 100vh;


	padding: 0 1rem;


}


.error-404 {


	height: 100vh;


	padding: 0 1rem;


}


.error-social a {


	display: inline-block;


	width: 40px;


	height: 40px;


	line-height: 40px;


	font-size: 18px;


	color: #fff;


	text-align: center;


	border-radius: 50%;


	margin: 5px;


	box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .075)!important;


}


.bg-login {


	/* background-image: url(../images/login-images/bg-login-img.jpg); */


	/* background-size: cover; */


	background-position: center;


	background-repeat: no-repeat;


	background-attachment: fixed;


}


.bg-forgot {


	/* background-image: url(../images/login-images/bg-forgot-password.jpg); */


	/* background-size: cover; */


	background-position: center;


	background-repeat: no-repeat;


	background-attachment: fixed;


}


.bg-lock-screen {


	/* background-image: url(../images/login-images/bg-lock-screen.jpg); */


	/* background-size: cover; */


	background-position: center;


	background-repeat: no-repeat;


	background-attachment: fixed;


}


.login-separater span {


	position: relative;


	top: 26px;


	margin-top: -10px;


	background: #ffffff;


	padding: 5px;


	font-size: 12px;


	color: #cbcbcb;


	z-index: 1;


}


/* Buttons */


.btn i {


	vertical-align: middle;


	font-size: 1.3rem;


	margin-top: -1em;


	margin-bottom: -1em;


	margin-right: 5px;


}


.btn-facebook {


	box-shadow: 0 2px 2px 0 rgba(59, 89, 152, 0.14), 0 3px 1px -2px rgba(59, 89, 152, 0.2), 0 1px 5px 0 rgba(59, 89, 152, 0.12);


	background-color: #3b5998;


	border-color: #3b5998;


	color: #fff;


}


.btn-facebook:hover {


	color: #fff;


}


.btn-white {


	background-color: #fff;


	border-color: #e7eaf3;


}


.chart-container1 {


	position: relative;


	height: 340px;


}


.gmaps,


.gmaps-panaroma {


	height: 400px;


	background: #eeeeee;


	border-radius: 3px;


}


/* Pricing Table*/


.pricing-table .card {


	-webkit-transition: all 0.2s;


	-o-transition: all 0.2s;


	transition: all 0.2s;


	-webkit-border-radius: 15px;


	border-radius: 15px;


}


.pricing-table .card .card-header {


	border-top-left-radius: 15px;


	border-top-right-radius: 15px;


}


@media (min-width: 992px) {


	.pricing-table .card:hover {


		margin-top: -.25rem;


		margin-bottom: .25rem;


		-webkit-box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.3);


		box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.3);


	}


}


.pricing-table .card .card-title {


	font-size: 1rem;


	letter-spacing: .2rem;


	font-weight: 500;


}


.pricing-table .card .card-price {


	font-size: 2.7rem;


}


.pricing-table .card .card-price .term {


	font-size: .875rem;


}


.pricing-table .card ul li.list-group-item {


	border-bottom: 1px solid rgb(0 0 0 / 0%);


	color: #3b3b3b;


	font-size: 16px;


}


/*Forms */


input::placeholder {


	color: #000 !important;


	opacity: .3 !important


}


.card-group {


	margin-bottom: 1.5rem;


}


.search-bar-box {


	width: 75%;

	padding-left: 30px;


}


.search-control {


	background-color: #f6f5f5;


	border: 1px solid #f2efef;


	padding-right: 2.5rem


}


.search-show {


	font-size: 18px;


	right: 15px;


}


.search-close {


	font-size: 18px;


	right: 15px;


	cursor: pointer;


	display: none;


}


.topbar .navbar .navbar-nav .nav-link {


	padding-right: 0.8rem;


	padding-left: 0.8rem;


	color: #252323;


	font-size: 22px;


}


.dropdown-toggle-nocaret:after {


	display: none


}


.alert-count {


	position: absolute;


	top: 5px;


	left: 22px;


	width: 18px;


	height: 18px;


	display: flex;


	align-items: center;


	justify-content: center;


	border-radius: 50%;


	font-size: 12px;


	font-weight: 500;


	color: #fff;


	background: #f62718;


}


.user-img {


	width: 42px;


	height: 42px;


	border-radius: 50%;


	border: 0px solid #e5e5e5;


	padding: 0px;


}


.user-info .user-name {


	font-size: 15px;


	font-weight: 500;


	color: #413c3c;


}


.user-info .designattion {


	font-size: 13px;


	color: #a9a8a8;


}


.user-box {


	display: flex;


	align-items: center;


	height: 60px;


	border-left: 1px solid #f0f0f0;


	margin-left: 1rem;


}


.dropdown-large {


	position: relative;


}


.dropdown-large .dropdown-menu {


	width: 360px;


	border: 0;


	padding: 0rem 0;


	box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, .15);


}


.topbar .navbar .dropdown-large .dropdown-menu::after {


	content: '';


	width: 13px;


	height: 13px;


	background: #ffffff;


	position: absolute;


	top: -6px;


	right: 16px;


	transform: rotate(45deg);


	border-top: 1px solid #ddd;


	border-left: 1px solid #ddd;


}


.topbar .navbar .dropdown-menu::after {


	content: '';


	width: 13px;


	height: 13px;


	background: #ffff;


	position: absolute;


	top: -6px;


	right: 16px;


	transform: rotate(45deg);


	border-top: 1px solid #ddd;


	border-left: 1px solid #ddd;


}


.dropdown-large .msg-header {


	padding: 0.8rem 1rem;


	border-bottom: 1px solid #ededed;


	background-clip: border-box;


	background: #ffffff;


	text-align: left;


	display: flex;


	align-items: center;


	border-top-left-radius: .25rem;


	border-top-right-radius: .25rem;


}


.dropdown-large .msg-header .msg-header-title {


	font-size: 15px;


	color: #1c1b1b;


	margin-bottom: 0;


	font-weight: 500;


}


.dropdown-large .msg-header .msg-header-clear {


	font-size: 12px;


	color: #585858;


	margin-bottom: 0;


}


.dropdown-large .msg-footer {


	padding: 0.8rem 1rem;


	color: #1c1b1b;


	border-top: 1px solid #ededed;


	background-clip: border-box;


	background: transparent;


	font-size: 14px;


	font-weight: 500;


	border-bottom-left-radius: .25rem;


	border-bottom-right-radius: .25rem;


}


.dropdown-large .user-online {


	position: relative;


}


.dropdown-large .msg-name {


	font-size: 14px;


	margin-bottom: 0;


}


.dropdown-large .msg-info {


	font-size: 13px;


	margin-bottom: 0;


}


.dropdown-large .msg-avatar {


	width: 45px;


	height: 45px;


	border-radius: 50%;


	margin-right: 15px;


}


.dropdown-large .msg-time {


	font-size: 12px;


	margin-bottom: 0;


	color: #919191;


}


.dropdown-large .user-online:after {


	content: '';


	position: absolute;


	bottom: 1px;


	right: 17px;


	width: 8px;


	height: 8px;


	border-radius: 50%;


	box-shadow: 0 0 0 2px #fff;


	background: #16e15e;


}


.dropdown-large .dropdown-menu .dropdown-item {


	padding: .50rem 1.3rem;


	border-bottom: 1px solid #ededed;


}


.header-message-list {


	position: relative;


	height: 360px;


}


.header-notifications-list {


	position: relative;


	height: 360px;


}


.dropdown-large .notify {


	width: 45px;


	height: 45px;


	line-height: 45px;


	font-size: 22px;


	text-align: center;


	border-radius: 50%;


	background-color: #f1f1f1;


	margin-right: 15px;


}


.app-box {


	width: 45px;


	height: 45px;


	display: flex;


	align-items: center;


	font-size: 26px;


	justify-content: center;


	cursor: pointer;


	border-radius: 10px;


	background-color: #f6f6f6;


}


.app-title {


	font-size: 14px;


}


.user-box .dropdown-menu i {


	vertical-align: middle;


	margin-right: 10px;


}


.dropdown-menu {


	-webkit-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, .15);


	box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, .15);


	border: 0px solid #e9ecef;


	font-size: 14px;


}


.topbar .navbar .dropdown-menu {


	-webkit-animation: .6s cubic-bezier(.25, .8, .25, 1) 0s normal forwards 1 animdropdown;


	animation: .6s cubic-bezier(.25, .8, .25, 1) 0s normal forwards 1 animdropdown;


}


@-webkit-keyframes animdropdown {


	from {


		-webkit-transform: translate3d(0, 6px, 0);


		transform: translate3d(0, 6px, 0);


		opacity: 0


	}


	to {


		-webkit-transform: translate3d(0, 0, 0);


		transform: translate3d(0, 0, 0);


		opacity: 1


	}


}


@keyframes animdropdown {


	from {


		-webkit-transform: translate3d(0, 6px, 0);


		transform: translate3d(0, 6px, 0);


		opacity: 0


	}


	to {


		-webkit-transform: translate3d(0, 0, 0);


		transform: translate3d(0, 0, 0);


		opacity: 1


	}


}


.mobile-search-icon {


	display: none;


}


.mobile-toggle-menu {


	display: none;


	font-size: 26px;


	color: #404142;


	cursor: pointer;


}


/*Switcher*/


.switcher-wrapper {


	width: 280px;


	height: 100%;


	position: fixed;


	right: -280px;


	top: 0;


	bottom: 0;


	z-index: 16;


	background: #fff;


	border-left: 0px solid #d2d2d2;


	box-shadow: 0 0.3rem 0.6rem rgba(0, 0, 0, .13);


	transition: all .2s ease-out;


}


.switcher-btn {


	width: 40px;


	height: 40px;


	line-height: 40px;


	font-size: 24px;


	background: #0d6efd;


	box-shadow: 0 0.3rem 0.6rem rgba(0, 0, 0, .13);


	color: #fff;


	text-align: center;


	border-top-left-radius: 10px;


	border-bottom-left-radius: 10px;


	position: absolute;


	top: 40%;


	right: 100%;


	cursor: pointer;


}


.switcher-wrapper.switcher-toggled {


	right: 0px;


}


.switcher-body {


	padding: 1.25rem;


}


.switcher-body .form-check .form-check-input,


.switcher-body .form-check .form-check-label {


	cursor: pointer;


}


.header-colors-indigators .indigator {


	width: 45px;


	height: 45px;


	background: #f4f2f2;


	border-radius: 10px;


	cursor: pointer;


}


/* Responsive */


@media screen and (max-width: 1280px) {

	.topbar-logo-header{

		display: none;

	}

	.page-wrapper {

		margin-top: 60px;

	}

	.mobile-toggle-menu {


		display: block;


	}

	.nav-container {

		position: fixed;

		width: 260px;

		right: 0;

		left: -260px;

		z-index: 16;

		border-bottom: 1px solid #e4e4e4;

		background-clip: border-box;

		background: #ffffff;

		height: 100%;

		padding: 10px;

		display: flex;

		align-items: start;

		justify-content: flex-start;

		overflow-y: scroll;

	    overflow-x: hidden;

	}

	.wrapper.toggled .nav-container {

		display: flex;

		left: 0px;

	}

	.wrapper.toggled .mobile-topbar-header {

		display: flex;

		align-items: center;

		height: 60px;

		background: white;

		border-bottom: 1px solid #f1f1f1;

		background-clip: border-box;

		position: fixed;

		width: 260px;

		left: 0;

		top: 0;

		right: 0;

		z-index: 10;

		padding: 0 30px;

	}

	.wrapper.toggled .overlay {

		position: fixed;

		top: 0;

		right: 0;

		bottom: 0;

		left: 0;

		background: #000;

		opacity: 0.6;

		z-index: 15;

		display: block;

		cursor: move;

		transition: all .2s ease-out;

	}

	.left-topbar {

		width: auto;

	}

	.search-bar {

		padding: 0rem 0rem 0 30px;

	}

	.search-bar-box {

		padding-left: 0px;

	}

	.email-header {

		height: auto;

	}

	.email-content {

		padding: 100px 0px 0px 0px;

	}

}


@media screen and (max-width: 1024px) {


	.topbar {


		left: 0px !important;


	}


	.mobile-search-icon {


		display: block;


	}


	


	
	.page-footer {


		left: 0px;


	}


	.search-bar {


		display: none;


	}


	.full-search-bar {


		display: flex;


		align-items: center;


		width: 100%;


		position: absolute;


		left: 0;


		z-index: 100;


		background: #22263a;


		height: 60px;


		padding: 0rem 1.4rem;


	}


	.search-bar-box {


		width: 100%;


	}


	.search-close {


		display: block;


	}


	.search-show {


		left: 15px;


		right: auto;


	}


	.search-control {


		background-color: #fff;


		border: 1px solid #f2efef00;


		padding-left: 2.5rem;


	}


	.wrapper.toggled .overlay {


		position: fixed;


		top: 0;


		right: 0;


		bottom: 0;


		left: 0;


		background: #000;


		opacity: 0.6;


		z-index: 10;


		display: block;


		cursor: move;


		transition: all .2s ease-out;


	}


	.error-404 {


		height: auto;


		padding: 6.0rem 1rem;


	}


	.chat-header {


		border-top-left-radius: 0.25rem;


	}


	.chat-footer {


		border-bottom-left-radius: 0.25rem;


	}


	.chat-sidebar {


		left: -370px;


	}


	.chat-content {


		margin-left: 0px;


	}


	.chat-header {


		left: 0px;


	}


	.chat-footer {


		left: 0px;


	}


	/* chat toggled css */


	.chat-toggled .chat-sidebar {


		left: 0px;


	}


	.chat-toggled .overlay {


		position: absolute;


		top: 0;


		right: 0;


		bottom: 0;


		left: 340px;


		background: #000;


		opacity: 0.5;


		z-index: 11;


		display: block;


		cursor: move;


		transition: all .3s ease-out;


	}


	.email-header {


		border-top-left-radius: 0.25rem;


	}


	.email-sidebar {


		left: -280px;


	}


	.email-content {


		margin-left: 0px;


	}


	.email-header {


		left: 0px;


	}


	/* email toggled */


	.email-toggled .email-sidebar {


		left: 0px;


	}


	.email-toggled .overlay {


		position: absolute;


		top: 0;


		right: 0;


		bottom: 0;


		left: 250px;


		background: #000;


		opacity: 0.5;


		z-index: 9;


		display: block;


		cursor: move;


		transition: all .3s ease-out;


	}


}


@media screen and (max-width: 991px) {


	.section-authentication-signin {


		height: 100%;


		margin-top: 6rem;


		margin-bottom: 2rem;


	}


	.authentication-reset-password {


		height: auto;


		padding: 2.0rem 1rem;


	}


	.authentication-lock-screen {


		height: auto;


		padding: 2.0rem 1rem;


	}


	.compose-mail-popup {


		width: auto;


		position: fixed;


		bottom: -30px;


		right: 0;


		left: 0;


	}


}


@media screen and (max-width: 767px) {


	.user-box .user-info {


		display: none;


	}


	.authentication-forgot {


		height: auto;


		padding: 2.5rem 1rem;


	}


}


@media screen and (max-width: 620px) {


	.topbar .navbar .dropdown-menu::after {


		display: none;


	}


	.topbar .navbar .dropdown {


		position: static !important;


	}


	.topbar .navbar .dropdown-menu {


		width: 100% !important;


	}


}


@media screen and (max-width: 520px) {


	.chat-footer-menu,


	.chat-top-header-menu {


		display: none;


	}


}

.highlight-red {
	color: red;
  }

  .timer-clock {
	font-family: 'Courier New', Courier, monospace;
	font-size: 2rem;
	color: #333;
	display: flex;
	justify-content: center;
	align-items: center;
  }
  
  .timer-clock span {
	display: inline-block;
	width: 2ch;
	text-align: center;
  }
  
  .timer-clock.inactive {
	color: #ccc;
  }

  .section-title {
	font-size: 1.5rem;
	font-weight: bold;
	color: #343a40;
  }
  
  .filter-container {
	display: flex;
	align-items: center;
  }
  
  .custom-select {
	border-radius: 0.375rem;
	padding: 0.375rem 1rem;
	font-size: 0.875rem;
	color: #495057;
	border: 1px solid #ced4da;
	transition: border-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  }
  
  .custom-select:focus {
	border-color: #6c757d;
	box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.25);
  }
  
  .modern-table {
    overflow: visible;
    position: static;
    margin-right: auto;
    min-height: max-content;
    min-width: max-content;
}

  .modern-table th {
	background-color: #f8f9fa;
	color: #495057;
	font-size: 0.9rem;
	font-weight: 600;
	text-transform: uppercase;
	letter-spacing: 0.05em;
  }
  
  .modern-table td {
	font-size: 0.875rem;
	color: #343a40;
  }

  
  .no-data {
	font-size: 1rem;
	color: #6c757d;
	font-weight: 500;
  }

  .duration-tooltip {
    position: relative; /* Mudei para relative */
    cursor: pointer;
    color: #007bff;
    text-decoration: underline dotted;
    display: contents;
}

.duration-tooltip:hover::after {
    content: attr(title);
    position: absolute;
    transform: translateX(-50%);
    z-index: 1050; /* Eleva o tooltip acima de outros conteúdos */
    background: rgba(0, 0, 0, 0.9); /* Fundo escuro */
    color: #ffffff; /* Cor do texto */
    padding: 10px 15px; /* Espaçamento interno */
    border-radius: 5px; /* Bordas arredondadas */
    white-space: nowrap;
    font-size: 14px; /* Tamanho do texto */
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.4); /* Sombra */
    text-align: center; /* Centraliza o texto */
    opacity: 1;
    visibility: visible;
    pointer-events: none;
}