.layout-container {
    display: flex;
    height: 100vh; /* Ocupa toda a altura da janela */
    overflow: hidden;
  }
  
  .sidebar {
    width: 250px; /* Largura fixa do sidebar */
    transition: transform 0.3s ease-in-out;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    z-index: 1000;
  }
  
  .sidebar.closed {
    transform: translateX(-100%); /* Esconde o sidebar */
  }
  
  .sidebar.open {
    transform: translateX(0); /* Exibe o sidebar */
  }
  
  .main-content {
    margin-left: 0;
    transition: margin-left 0.3s ease-in-out;
    width: 100%;
  }
  
  .main-content.sidebar-open {
    margin-left: 250px; /* Empurra o conteúdo para a direita quando o sidebar está aberto */
  }
  
  .content-area {
    padding: 80px 20px 20px 20px; /* Espaçamento para o header fixo */
    overflow-y: auto;
    height: 100%;
  }