/* Geral */
body {
  font-family: "Roboto", sans-serif;
  background-color: #f4f6f9;
  color: #333;
  margin: 0;
  padding: 0;
}

.calendar-container {
  margin: 0 auto;
  width: 100%;
  max-width: 1200px; /* Largura máxima para manter o design compacto */
  height: 100%;
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  font-family: "Roboto", sans-serif;
  padding: 20px;
}

/* Título */
.calendar-container h1 {
  text-align: center;
  margin-bottom: 20px;
  font-size: 1.8rem;
  color: #ffffff;
  font-weight: bold;
}

/* FullCalendar */
.fc {
  height: 100%; /* Ocupa 100% da altura do contêiner */
  width: 100%; /* Ocupa 100% da largura */
  background-color: #ffffff;
  border-radius: 10px;
}

/**
/* SE PRECISAR DE MAIS ESPACO NO SEMANAL SO HABILITAR */
/* Define altura mínima para todas as divisões de tempo */
.fc-timegrid-slot {
  min-height: 40px !important; /* Ajuste conforme necessário */
  height: 40px !important; /* Altura fixa para consistência */
}
/*
/* Inclui os rótulos das horas para garantir alinhamento */
.fc-timegrid-slot-label {
  min-height: 40px !important;
  height: 40px !important;
  line-height: 40px; /* Centraliza verticalmente o texto */
}
/*
/* Garante que o lane (espaço onde os eventos são renderizados) siga o mesmo tamanho */
.fc-timegrid-slot-lane {
  min-height: 40px !important;
  height: 40px !important;
}

/* Altura mínima e fonte do cartão do evento */
.fc-timeGrid-event {
  font-size: 1rem;
  white-space: normal !important;
  overflow-wrap: break-word;
  height: auto !important; /* Permite que o evento se ajuste ao conteúdo */
}
/* Estilo do cabeçalho */
.fc-toolbar {
  margin-bottom: 20px;
  background-color: #f8f9fa;
  border-radius: 8px;
  padding: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.fc-toolbar h2 {
  font-size: 1.4rem;
  color: #333;
  font-weight: bold;
}

.fc-button-group {
  display: flex;
  gap: 10px;
}

/* Botões do FullCalendar */
.fc-button {
  background-color: #ffffff;
  border: none;
  color: #ffffff;
  font-size: 0.9rem;
  padding: 8px 12px;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.fc-button:hover {
  background-color: #0056b3;
  transform: scale(1.05);
}

.fc-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

/* Dias da semana */
.fc-col-header-cell {
  background: #f8f9fa;
  color: #ffffff;
  font-weight: bold;
  text-align: center;
  padding: 10px 0;
  border-radius: 6px;
}

/* Dias do mês */
.fc-daygrid-day {
  background: #ffffff;
  color: #333333;
  padding: 10px;
  border: 1px solid #e6e6e6;
  transition: background-color 0.3s ease;
}

.fc-daygrid-day:hover {
  background-color: #f8f9fa;
  cursor: pointer;
}

/* Estilo para o dia atual */
.fc-day-today {
  background-color: rgba(0, 123, 255, 0.1);
  border: 2px solid #ffffff;
  border-radius: 6px;
}

/* Eventos */
.fc-event {
  color: #ffffff;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 0.9rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  overflow-wrap: break-word;
  white-space: normal;
  height: auto !important; /* Permite que eventos com descrição longa expandam */
}

.fc-event:hover {
  cursor: pointer;
}

/* Colunas de dias da semana */
.fc-timegrid-col {
  border: 1px solid #e6e6e6;
  background-color: #ffffff;
}

/* Tooltip para eventos */
.fc-popover {
  background-color: #ffffff;
  border: 1px solid #ccc;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  font-size: 0.9rem;
  padding: 10px;
}

.fc-event.due-date-event {
  background-color: #ff5722 !important; /* Cor específica para due dates */
  border: none !important;
  color: white !important;
}


/* Botão "mais eventos" */
.fc-daygrid-more-link {
  color: #ffffff;
  text-decoration: none;
  font-weight: bold;
  cursor: pointer;
}

.fc-daygrid-more-link:hover {
  text-decoration: underline;
}

/* Barra de navegação e hoje */
.fc-now-indicator {
  background-color: #dc3545;
  height: 2px;
}

.mechanic-buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 15px;
}

.mechanic-button {
  padding: 8px 12px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.mechanic-button.active {
  background-color: #0056b3;
}

.mechanic-button:hover {
  background-color: #0056b3;
}

/* Paleta de cores para mecânicos */
.mechanic-color-1 {
  --event-color: #ffc107;
  --event-hover-color: #e0a800;
}

.mechanic-color-6 {
  --event-color: #9807ff;
  --event-hover-color: #9807ff;
}

.mechanic-color-3 {
  --event-color: #17a2b8;
  --event-hover-color: #117a8b;
}

.mechanic-color-4 {
  --event-color: #dc3545;
  --event-hover-color: #bd2130;
}

.mechanic-color-5 {
  --event-color: #28a745;
  --event-hover-color: #218838;
}

/* Adicione cores adicionais conforme necessário */

/* Responsividade */
@media (max-width: 768px) {
  .calendar-container {
    padding: 10px;
  }

  .fc-toolbar h2 {
    font-size: 1.2rem;
  }

  .fc-button {
    padding: 6px 10px;
    font-size: 0.8rem;
  }

  .fc-daygrid-day {
    padding: 8px;
  }

  .fc-event {
    font-size: 0.8rem;
    padding: 4px;
  }
}

/* Estilo do Modal */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Fundo semitransparente */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.modal-content {
  background: #ffffff;
  border-radius: 12px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Sombra mais definida */
  width: 90%;
  max-width: 500px;
  padding: 20px;
  position: relative;
  animation: fadeIn 0.3s ease;
}

.modal-content h4 {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 20px;
  color: #333333;
  text-align: center;
}

.modal-content label {
  font-size: 1rem;
  color: #555555;
  margin-bottom: 5px;
  display: block;
}

.modal-content input,
.modal-content textarea,
.modal-content .form-control {
  width: 100%;
  padding: 10px 15px;
  margin-bottom: 15px;
  border: 1px solid #cccccc;
  border-radius: 8px;
  font-size: 1rem;
  transition: border-color 0.3s ease;
}

.modal-content input:focus,
.modal-content textarea:focus {
  border-color: #007bff;
  outline: none;
  box-shadow: 0 0 4px rgba(0, 123, 255, 0.5);
}

.modal-content .btn-container {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.modal-content .btn {
  padding: 10px 20px;
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.modal-content .btn-primary {
  background-color: #007bff;
  color: #ffffff;
}

.modal-content .btn-primary:hover {
  background-color: #0056b3;
  transform: scale(1.05);
}

.modal-content .btn-secondary {
  background-color: #6c757d;
  color: #ffffff;
}

.modal-content .btn-secondary:hover {
  background-color: #5a6268;
  transform: scale(1.05);
}

/* Animação de entrada */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Responsividade */
@media (max-width: 768px) {
  .modal-content {
    padding: 15px;
  }

  .modal-content h4 {
    font-size: 1.2rem;
  }

  .modal-content .btn {
    font-size: 0.9rem;
  }
}

.form-grid {
  display: grid;
  grid-template-columns: 1fr 1fr; /* Duas colunas iguais */
  gap: 16px; /* Espaçamento entre os campos */
}

.form-group {
  display: flex;
  flex-direction: column;
}

/* Popup lateral (info-box) */
.info-box {
  position: absolute;
  background: #ffffff;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  padding: 20px;
  min-width: 250px;
  z-index: 1000;
}

.info-box .btn-container {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.info-box .btn {
  padding: 8px 12px;
  font-size: 0.9rem;
  border-radius: 6px;
}

.info-box .btn-primary {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 8px 12px;
  border-radius: 6px;
  cursor: pointer;
  font-size: 0.9rem;
}

.info-box .btn-primary:hover {
  background-color: #0056b3;
}

.info-box .btn-container {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.info-box .btn-danger {
  background-color: #dc3545;
  color: #fff;
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 1.2rem;
  cursor: pointer;
}


/* Calendar.css */

/* Estilização Geral do DatePicker */
.react-datepicker {
  font-family: 'Roboto', sans-serif;
  background-color: #ffffff; /* Fundo branco */
  border: 1px solid #cccccc; /* Borda cinza clara */
  border-radius: 8px; /* Bordas arredondadas */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Sombra sutil */
  width: 100%; /* Ocupa 100% do container */
}

/* Cabeçalho do DatePicker */
.react-datepicker__header {
  background-color: #f0f0f0; /* Cinza muito claro */
  border-bottom: 1px solid #cccccc;
  padding: 10px 0;
  border-radius: 8px 8px 0 0;
}

/* Navegação do Cabeçalho */
.react-datepicker__navigation {
  top: 10px;
  line-height: 1.7em;
}

.react-datepicker__navigation-icon::before {
  border-color: #333333; /* Ícones de navegação em preto */
}

/* Dias da Semana */
.react-datepicker__day-name {
  color: #333333; /* Texto preto */
  font-weight: bold;
}

/* Dias do Mês */
.react-datepicker__day {
  color: #555555; /* Texto cinza escuro */
  font-size: 1rem; /* Aumenta o tamanho da fonte */
  line-height: 2rem; /* Aumenta o espaçamento vertical */
  border-radius: 50%; /* Torna os dias circulares */
  transition: background-color 0.3s, color 0.3s;
}

/* Dia Selecionado */
.react-datepicker__day--selected,
.react-datepicker__day--keyboard-selected {
  background-color: #333333; /* Fundo preto */
  color: #ffffff; /* Texto branco */
  border: 2px solid #ffffff; /* Borda branca para destacar */
}

/* Dia Hover */
.react-datepicker__day:hover {
  background-color: #e6e6e6; /* Cinza claro ao passar o mouse */
  color: #333333;
}

/* Dias Desabilitados */
.react-datepicker__day--disabled {
  color: #cccccc;
  pointer-events: none;
}

/* Time Picker */
.react-datepicker-time__header {
  background-color: #f0f0f0;
  border-bottom: 1px solid #cccccc;
}

.react-datepicker__time-container .react-datepicker__time {
  background-color: #ffffff;
  border-left: 1px solid #cccccc;
}
/* Ajuste no horário dos eventos no listWeek */
.fc-list-event-time {
  font-size: 0.9rem; /* Ajuste do tamanho da fonte */
  display: contents;
  color: #333; /* Cor do texto */
  white-space: nowrap; /* Impede quebra de linha */
  overflow: hidden; /* Oculta texto longo */
  text-overflow: ellipsis; /* Adiciona reticências se o texto for muito longo */
}

/* Ajuste do título dos eventos */
.fc-list-event-title {
  font-size: 1rem; /* Tamanho do título */
  display: contents;
  color: #333333; /* Cor do título */
  overflow: hidden; /* Oculta texto longo */
  text-overflow: ellipsis; /* Adiciona reticências ao título longo */
  white-space: nowrap; /* Impede quebra de linha */
  flex: 1; /* Permite que o título ocupe o espaço restante */
}

/* Layout geral do item da lista */
.fc-list-event {
  display: flex; /* Usa flexbox para alinhamento horizontal */
  align-items: center; /* Alinha verticalmente o horário e o título */
  padding: 10px 15px; /* Espaçamento interno */
  border-bottom: 1px solid #e6e6e6; /* Linha separadora */
  background-color: #ffffff; /* Fundo branco */
  transition: background-color 0.3s ease;
}

.fc .fc-list-event-dot {
  border: calc(var(--fc-list-event-dot-width) / 2) solid var(--fc-event-border-color);
  border-radius: calc(var(--fc-list-event-dot-width) / 2);
  box-sizing: content-box;
  display: inline-block;
  margin: 0px 10px;
  height: 0px;
  width: 0px;
}

.fc-direction-ltr .fc-list-table .fc-list-event-graphic {
  padding-right: 0px;
  display: contents;
}

.fc-list-event:hover {
  background-color: #f8f9fa; /* Fundo mais claro ao passar o mouse */
}
/* Botões de Seleção de Hora */
.react-datepicker__time-list-item {
  padding: 8px 12px;
  color: #333333;
}

.react-datepicker__time-list-item:hover {
  background-color: #e6e6e6;
  cursor: pointer;
}

/* Responsividade */
@media (max-width: 768px) {
  .react-datepicker {
    font-size: 0.9rem;
  }

  .react-datepicker__day {
    font-size: 0.9rem;
    line-height: 1.8rem;
  }
}

/* Custom Time Header */
.react-datepicker__time-header {
  font-size: 1rem;
  font-weight: bold;
  color: #333333;
  padding: 8px 0;
  text-align: center;
}