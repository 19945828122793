.authorized-locations {
    max-width: 500px;
    margin: 40px auto;
    padding: 20px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border: 1px solid #eaeaea;
  }
  
  .authorized-locations h1 {
    font-size: 1.8rem;
    text-align: center;
    margin-bottom: 20px;
    color: #333;
  }
  
  .authorized-locations form {
    display: flex;
    flex-direction: column;
  }
  
  .authorized-locations form input[type="text"],
  .authorized-locations form input[type="number"] {
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1rem;
  }
  
  .authorized-locations form button {
    padding: 10px;
    background-color: #007bff;
    border: none;
    color: #fff;
    border-radius: 4px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .authorized-locations form button:hover {
    background-color: #0056b3;
  }