/* src/pages/Database.css */
.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 1rem;
  }
  
  /* Estilização dos Cards */
  .card {
    margin-bottom: 1.5rem;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  }
  
  .card-header {
    background-color: #007bff;
    color: #fff;
    padding: 0.75rem 1.25rem;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
  
  .card-body {
    padding: 1.25rem;
  }
  
  /* Botões */
  .btn {
    border-radius: 4px;
  }
  
  /* Tabela Responsiva */
  .table {
    width: 100%;
    margin-bottom: 1rem;
  }
  
  /* Estilização para visualização dos detalhes full page */
  .detail-card {
    margin-top: 2rem;
  }
  
  /* Responsividade para Mobile */
  @media (max-width: 768px) {
    .container {
      padding: 0.5rem;
    }
    .card-header {
      padding: 0.5rem 1rem;
      font-size: 1.1rem;
    }
    .card-body {
      padding: 1rem;
    }
    .form-label {
      font-size: 0.9rem;
    }
    .form-control, .form-select {
      font-size: 0.9rem;
    }
    .btn {
      font-size: 0.9rem;
      padding: 0.5rem 1rem;
    }
  }