/* Geral */
body {
    font-family: "Roboto", sans-serif;
    background-color: #f9fafb;
    color: #2d2d2d;
    margin: 0;
    padding: 0;
}

/* Tickets Wrapper */
.tickets-wrapper {
    display: flex;
    gap: 10px;
    height: 100vh;
    padding: 15px;
    box-sizing: border-box;
}

.ticket-list-item {
  position: relative;
}

.ticket-list-item:hover .delete-button {
  display: block;
}

.delete-button {
  display: none;
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  color: #ff0000;
  font-size: 20px;
  cursor: pointer;
}

.delete-button:hover {
  color: #b30000;
}

/* Sidebar */
.tickets-sidebar {
    flex: 0 0 100%;
    background: #ffffff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    overflow: hidden;
    transition: all 0.3s ease-in-out;
}

.tickets-sidebar-header {
    padding: 15px;
    border-bottom: 1px solid #e0e0e0;
    background: #f5f6f8;
    font-weight: 600;
    font-size: 16px;
    color: #2d2d2d;
}

.input-group {
    display: flex;
    gap: 10px;
    margin-top: 10px;
}

.input-group input,
.input-group select {
    flex: 1;
    border: 1px solid #d1d1d1;
    border-radius: 6px;
    padding: 8px;
    font-size: 14px;
    background-color: #ffffff;
    transition: border-color 0.2s ease;
}

.input-group input:focus,
.input-group select:focus {
    outline: none;
    border-color: #007bff;
}

/* Lista de Tickets */
.tickets-list {
    flex-grow: 1;
    overflow-y: auto;
    padding: 10px;
}

.list-group-item {
    padding: 15px;
    margin-bottom: 10px;
    background: #ffffff;
    border: 1px solid #e6e6e6;
    border-radius: 6px;
    cursor: pointer;
    transition: all 0.2s ease;
}

.list-group-item:hover {
    background: #f8f9fa;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.list-group-item.active {
    background: #007bff;
    color: #ffffff;
    border-color: #007bff;
}

.ticket-title {
    font-size: 14px;
    font-weight: 500;
    margin: 0;
    color: #2d2d2d;
}

.ticket-msg {
    font-size: 12px;
    color: #666666;
    margin: 5px 0 0;
}

.ticket-status {
    font-size: 11px;
    font-weight: bold;
    padding: 2px 6px;
    border-radius: 12px;
    display: inline-block;
}

.ticket-status.pending {
    background: #ffc107;
    color: #ffffff;
}

.ticket-status.finished {
    background: #28a745;
    color: #ffffff;
}

.ticket-status.working {
    background: #17a2b8;
    color: #ffffff;
}

.ticket-status.not-assigned {
    background: #dc3545;
    color: #ffffff;
}

.ticket-status.reservation {
  background: #9935dc;
  color: #ffffff;
}

.upload-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.8); /* fundo branco com transparência */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.upload-message {
  text-align: center;
}

.spinner {
  margin-top: 10px;
  border: 4px solid #f3f3f3;
  border-top: 4px solid #555;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Main Chat Section */
.tickets-main {
    flex-grow: 1;
    background: #ffffff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    overflow: hidden;
    min-height: 550px;
}

.tickets-header {
    padding: 15px;
    border-bottom: 1px solid #e0e0e0;
    background: #f5f6f8;
    font-size: 12px;
    font-weight: bold;
    color: #2d2d2d;
}

.tickets-content {
    flex-grow: 1;
    overflow-y: auto;
    padding: 20px;
    background: #f9fafb;
    position: relative;
    transition: all 0.3s ease;
}

.tickets-content.expanded {
  min-height: calc(100vh - 80px);
  padding: 30px 20px; 
}

/* Chat Mensagens */
.chat-message-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    padding: 10px;
    border-radius: 6px;
    max-width: 100%;
}

.chat-right {
    align-self: flex-end;
    background: #027bff;
    color: #ffffff;
    padding: 12px;
    text-align: right;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
}

.chat-time-right {
    font-size: 13px;
    margin-left: 5px;
    color: #fff;
}

.chat-left {
    align-self: flex-start;
    background: #f1f3f5;
    color: #2d2d2d;
    padding: 12px;
    text-align: left;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    border-radius: 6px;
}

.chat-message {
    font-size: 13px;
    margin: 0;
    word-wrap: break-word;
}

.chat-date-separator {
    text-align: center;
    font-size: 12px;
    font-weight: bold;
    color: #999999;
    margin: 20px 0;
    padding: 5px 0;
}

/* Container do input + botões, estilo "WhatsApp" */
.chat-input-container {
  display: flex;
  align-items: center;
  padding: 0.5rem;
  gap: 0.5rem;
}

/* Botões de câmera e enviar */
.chat-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #0084ff; /* cor de exemplo */
  color: #fff;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  cursor: pointer;
}

.camera-button {
  background-color: #ccc; /* ex: cinza para a câmera */
}

.send-button {
  font-size: 16px;
  background-color: #007bff; /* cor do "aviãozinho" */
}

/* Textarea que se expande de acordo com o texto */
.chat-textarea {
  flex: 1; /* ocupa todo o espaço restante */
  resize: none; /* remove o “arrastador” de redimensionar */
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 1rem;
  font-size: 1rem;
  line-height: 1.2;
}

/* Responsivo no mobile */
@media (max-width: 768px) {
  .chat-input-container {
    padding: 0.25rem;
    gap: 0.25rem;
  }
  .chat-button {
    width: 35px;
    height: 35px;
  }
  .chat-textarea {
    font-size: 0.9rem;
  }
}

/* Chat Input */
.chat-section {
    display: flex;
    padding: 15px;
    border-top: 1px solid #e0e0e0;
    background: #ffffff;
    gap: 10px;
}

.chat-section input {
    flex: 1;
    border: 1px solid #d1d1d1;
    border-radius: 20px;
    padding: 10px 15px;
    font-size: 14px;
    background-color: #ffffff;
    transition: border-color 0.2s ease;
}

.chat-section input:focus {
    outline: none;
    border-color: #007bff;
}

.chat-section button {
    padding: 10px 15px;
    border: none;
    color: #007bff;
    font-size: 20px;
    cursor: pointer;
    transition: background-color 0.2s ease;
}

/* Modal */
.modal-mechanic {
    padding: 25px;
    border-radius: 12px;
    background: #ffffff;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
    max-width: 600px;
    margin: auto;
    animation: fadeIn 0.3s ease-in-out;
    position: relative;
}

.modal-title {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 20px;
    color: #2d2d2d;
    border-bottom: 1px solid #e0e0e0;
    padding-bottom: 10px;
}

.modal-content {
    padding: 10px;
    font-size: 14px;
    line-height: 1.6;
    color: #4a4a4a;
}

/* Botões */
.btn-assign {
    background: #007bff;
    color: #ffffff;
    border: none;
    padding: 12px 18px;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.btn-assign:hover {
    background: #0056b3;
}

.btn-secondary {
    background: #6c757d;
    color: #ffffff;
    padding: 12px 18px;
    border-radius: 8px;
    border: none;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.btn-secondary:hover {
    background: #5a6268;
}

.modal-actions {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    margin-top: 20px;
}

/* Responsividade */
@media (max-width: 768px) {
    .modal-mechanic {
        max-width: 90%;
        padding: 20px;
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(-20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

/* Estilo para os slots */
.slot-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center; /* Centraliza horizontalmente */
    align-items: center; /* Centraliza verticalmente */
    gap: 15px; /* Maior espaçamento entre os slots */
    margin: 0 auto; /* Centraliza o container na página */
    padding: 15px;
    max-width: 800px; /* Limita a largura máxima */
    background: #f9fafb;
    border: 1px solid #e0e0e0;
    border-radius: 12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center; /* Garante o alinhamento do texto dentro dos slots */
}

/* Slot Item */
.slot-item {
    flex: 0 0 calc(25% - 0px); /* 4 slots por linha */
    padding: 15px;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    color: #2d2d2d;
    background: #ffffff;
    border: 1px solid #e6e6e6;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.slot-item:hover {
    background: #007bff;
    color: #ffffff;
    border-color: #007bff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.slot-item.active {
    background: #28a745;
    color: #ffffff;
    border-color: #28a745;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.slot-item.disabled {
    background: #f1f1f1;
    color: #b1b1b1;
    border-color: #e0e0e0;
    cursor: not-allowed;
    box-shadow: none;
}

/* Responsividade */
@media (max-width: 768px) {
    .slot-container {
        max-width: 100%; /* Remove a limitação da largura */
    }

    .slot-item {
        flex: 0 0 calc(50% - 15px); /* 2 slots por linha em telas menores */
    }
}

@media (max-width: 480px) {
    .slot-item {
        flex: 0 0 100%; /* 1 slot por linha em telas muito pequenas */
    }
}

.chat-header-msg {
    background-color: #f8f9fa;
    padding: 20px;
    border-bottom: 2px solid #007bff;
    border-radius: 8px 8px 0 0;
    margin-bottom: 15px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 20px;
}

.chat-client-details, .chat-vehicle-details {
    flex: 1;
    background: #ffffff;
    padding: 15px;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.chat-client-details h5, .chat-vehicle-details h5 {
    font-size: 18px;
    font-weight: bold;
    color: #2d2d2d;
    margin-bottom: 10px;
    border-bottom: 1px solid #dee2e6;
    padding-bottom: 5px;
}

.chat-client-details p, .chat-vehicle-details p {
    margin: 5px 0;
    font-size: 14px;
    color: #4a4a4a;
}

.chat-client-details p strong, .chat-vehicle-details p strong {
    font-weight: 600;
    color: #2d2d2d;
}

/* Ajuste geral para telas pequenas */
@media (max-width: 768px) {
    .tickets-wrapper {
        flex-direction: column; /* Alinhar itens verticalmente */
        gap: 0;
        padding: 0;
    }

    .tickets-sidebar {
        flex: 0 0 auto;
        width: 100%; /* Preencher a largura */
        margin-bottom: 10px;
    }

    .tickets-main {
        flex: 1;
        width: 100%;
        margin-top: 10px;
        box-shadow: none; /* Remove sombras para telas menores */
        min-height: 1300px;
    }

    .chat-section {
        flex-direction: column; /* Alinhar os inputs e botões verticalmente */
        gap: 10px;
    }

    .chat-section input {
        width: 100%;
        border-radius: 8px;
        font-size: 16px;
    }

    .chat-section button {
        border-radius: 8px;
        font-size: 20px;
    }

    .chat-message-container {
        max-width: 90%; /* Limitar a largura */
        word-wrap: break-word;
    }

    .chat-right,
    .chat-left {
        font-size: 14px; /* Reduzir tamanho da fonte */
        padding: 10px;
    }

    .chat-time-right,
    .chat-time {
        font-size: 12px;
    }

    .chat-header-msg {
        flex-direction: column; /* Alinhar os detalhes verticalmente */
        gap: 10px;
    }

    .chat-vehicle-details {
        padding: 10px;
        font-size: 14px;
    }
}

/* Ajustes específicos para telas muito pequenas */
@media (max-width: 480px) {
    .chat-section button {
        font-size: 20px; /* Reduzir ainda mais o tamanho da fonte */
    }
    .chat-section input,
    .chat-message-container {
        padding: 8px;
        font-size: 14px;
    }
}

.tickets-header .btn {
    min-width: 120px;
    margin-left: 5px;
  }
  
  .tickets-header .btn-primary {
    background-color: #007bff;
    color: #fff;
  }
  
  .tickets-header .btn-warning {
    background-color: #ffc107;
    color: #fff;
  }
  
  .tickets-header .btn-danger {
    background-color: #dc3545;
    color: #fff;
  }
  
  .tickets-header .btn:hover {
    opacity: 0.9;
  }

  .system-message {
  text-align: center;
  background: #e9f5ff;
  color: #007bff;
  border: 1px solid #007bff;
  border-radius: 12px;
  padding: 10px;
  margin: 10px 0;
  font-size: 14px;
  font-weight: bold;
}

.system-message-header {
  font-size: 12px;
  color: #666;
  margin-bottom: 5px;
}

.system-message-content {
  font-size: 14px;
  font-weight: 500;
}

.due-date-modal {
    position: absolute;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    transform: translate(-50%, -50%);
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    max-width: 500px;
    width: 100%;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    z-index: 1001; /* Define um z-index alto para garantir que fique na frente */
  }
  
  /* Estilos para o Overlay */
  .due-date-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75); /* Fundo mais escuro */
    z-index: 1000; /* Menor que o z-index do modal, mas ainda alto */
  }

  /* Estilização Minimalista para Botões de Filtro */
.filter-button {
    padding: 4px 8px; /* Menor padding para botões mais compactos */
    font-size: 12px; /* Tamanho de fonte reduzido */
    border-radius: 4px; /* Bordas levemente arredondadas */
    border: 1px solid transparent; /* Borda transparente por padrão */
    background-color: #f0f0f0; /* Cor de fundo suave */
    color: #333333; /* Cor do texto */
    cursor: pointer;
    transition: background-color 0.2s ease, color 0.2s ease;
  }
  
  /* Cores específicas para cada status */
  .filter-button.all {
    background-color: #e0e0e0;
  }
  
  .filter-button.not-assigned {
    background-color: #f8d7da;
    color: #842029;
  }
  
  .filter-button.pending {
    background-color: #fff3cd;
    color: #664d03;
  }
  
  .filter-button.working {
    background-color: #d1ecf1;
    color: #0c5460;
  }
  
  .filter-button.finished {
    background-color: #d4edda;
    color: #155724;
  }
  
  /* Estado Ativo */
  .filter-button.active {
    border-color: #007bff;
    background-color: #007bff;
    color: #ffffff;
  }
  
  /* Remover Efeito de Hover */
  .filter-button:hover {
    background-color: #dcdcdc; /* Leve alteração de cor para indicar interatividade */
    color: #333333;
  }
  
  /* Botões Desativados (Opcional) */
  .filter-button.disabled {
    background-color: #f8f9fa;
    color: #6c757d;
    cursor: not-allowed;
  }
  
  /* Espaçamento entre os botões */
  .filter-button:not(:last-child) {
    margin-right: 6px;
  }

  /* Tickets.css */
.chat-message img {
    max-width: 100%;
    max-height: 300px;
    object-fit: contain;
    border-radius: 5px;
  }

  /* Oculta o input de arquivo */
.file-input {
    display: none;
  }
  
  /* Estiliza o botão com ícone de câmera */
  .upload-button {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #007bff; /* Cor do botão */
    color: white; /* Cor do ícone/texto */
    border: none;
    border-radius: 50%; /* Forma circular */
    width: 50px;
    height: 50px;
    cursor: pointer;
    font-size: 24px;
    transition: background-color 0.3s ease;
  }
  
  .upload-button:hover {
    color: #212529; /* Cor ao passar o mouse */
  }
  
  .upload-button i {
    font-size: 24px; /* Tamanho do ícone */
  }

  /* DESKTOP */
.tickets-wrapper {
  display: flex;
  height: 100%;
}

.tickets-sidebar {
  border-right: 1px solid #ddd;
  /* No desktop, 350px fixos (ou outro valor) */
  width: 100%;
}

.ticket-details-chat {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  overflow: hidden;
}

.chat-container {
  /* Se quiser forçar rolagem no desktop */
  display: flex;
  flex-direction: column;
}

.chat-messages {
  flex: 1; /* ocupando o espaço */
  overflow-y: auto;
}

/* Container geral da versão mobile */
.mobile-layout-container {
    display: flex;
    flex-direction: column;
    height: 100vh; /* Se quiser ocupar a tela toda */
    overflow: hidden;
  }
  
  /* Lista de tickets em mobile */
  .tickets-list-mobile {
    flex: 1; /* Para ocupar o espaço */
    overflow-y: auto;
    padding: 1rem;
  }
  
  /* Cada item da lista */
  .ticket-item-mobile {
    padding: 0.8rem;
    margin-bottom: 0.5rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    cursor: pointer;
  }
  .ticket-item-mobile h6 {
    margin: 0;
    font-weight: 600;
  }
  .ticket-item-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  /* Chat em mobile */
  .mobile-chat-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 1rem;
  }
  
  /* Cabeçalho do ticket no chat */
  .ticket-header-mobile h5 {
    margin: 0 0 0.5rem 0;
    font-weight: 600;
  }
  .ticket-header-mobile p {
    margin: 0;
    font-size: 0.9rem;
  }
  
  /* Lista de mensagens no chat */
  .chat-messages-mobile {
    flex: 1;
    overflow-y: auto;
    margin-bottom: 0.5rem;
    border: 1px solid #ccc;
    padding: 1rem;
    border-radius: 4px;
  }
  
  /* Mensagens enviadas e recebidas */
  .chat-message {
    margin-bottom: 1rem;
    max-width: 100%;
  }
  .chat-message-sent {
    background-color: #d1e7dd;
    align-self: flex-end;
    padding: 6px 10px;
    border-radius: 8px;
  }
  .chat-message-received {
    background-color: #f8d7da;
    align-self: flex-start;
    padding: 6px 10px;
    border-radius: 8px;
  }
  
  /* Input e botão de envio */
  /* Container Principal do Chat */
.chat-section {
  padding: 10px;
  background-color: #f0f2f5; /* Fundo suave */
  border-top: 1px solid #ddd; /* Separação com as mensagens */
  width: 100%;
  box-sizing: border-box;
}

/* Container do Input e Botões */
.chat-input-container {
  display: flex;
  align-items: center;
  gap: 10px; /* Espaçamento entre os elementos */
  width: 100%;
  flex-wrap: nowrap; /* Garantir que os elementos fiquem em uma única linha */
}

/* Botões de Upload (Câmera e Arquivo) e Enviar */
.upload-button, .send-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: color 0.3s ease;
}

/* Cor inicial dos ícones */
.upload-button svg {
  color: #007bff; /* Azul para upload */
  font-size: 24px;
}

/* Hover sem mudar o fundo, apenas a cor do ícone */
.upload-button:hover svg {
  color: #0056b3; /* Azul mais escuro ao passar o mouse */
}

/* Esconder Inputs de Arquivo */
.file-input {
  display: none;
}

/* Input de Texto */
.chat-input {
  flex: 1; /* Ocupa o espaço restante */
  height: 40px;
  padding: 10px 15px; /* Padding horizontal */
  border: 1px solid #ccc; /* Borda cinza */
  font-size: 16px; /* Tamanho da fonte */
  outline: none; /* Remove o outline padrão */
  transition: border-color 0.3s, box-shadow 0.3s;
  background-color: #ffffff; /* Fundo branco */
}

/* Foco no Input de Texto */
.chat-input:focus {
  border-color: #007bff; /* Azul ao focar */
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5); /* Sombra suave */
}

/* Responsividade */

/* Desktop: Ajustes para telas maiores */
@media (min-width: 769px) {
  .chat-input-container {
    gap: 15px; /* Espaçamento aumentado para desktops */
  }

  .upload-button, .send-button {
    font-size: 24px; /* Tamanho maior dos ícones */
    padding: 10px; /* Aumento do padding para melhor clique */
  }

  .chat-input {
    height: 45px;
    padding: 12px 20px;
    font-size: 16px;
  }
}

/* Mobile: Semelhante ao WhatsApp, tudo em uma linha */
@media (max-width: 768px) {
  .chat-input-container {
    gap: 8px; /* Espaçamento reduzido */
  }

  .upload-button, .send-button {
    font-size: 20px; /* Tamanho reduzido dos ícones */
    padding: 8px;
  }

  .chat-input {
    height: 40px;
    padding: 10px 15px;
    font-size: 16px; /* Mantém tamanho para evitar zoom */
  }
}

/* Telas Muito Pequenas: Manter em linha única */
@media (max-width: 480px) {
  .chat-input-container {
    gap: 5px; /* Espaçamento ainda mais reduzido */
  }

  .upload-button, .send-button {
    font-size: 18px; /* Tamanho ainda menor */
    padding: 6px;
  }

  .chat-input {
    height: 35px;
    padding: 8px 12px;
    font-size: 16px;
  }
}

/* Garantir que todos os elementos fiquem em uma única linha */
.chat-input-container {
  flex-wrap: nowrap;
}

/* Ícones Sem Fundo */
.upload-button, .send-button {
  background: none;
  border: none;
  box-shadow: none;
}

/* Remover Bordas Arredondadas */
.upload-button, .send-button, .chat-input {
  border-radius: 0;
}

/* Estilo para Melhor Visibilidade dos Ícones */
.upload-button svg, .send-button svg {
  display: block;
}  

.page-wrapper-mobile {
  margin-top: 15px;
}

.message {
  padding: 10px;
  margin: 5px;
  border-radius: 5px;
  max-width: 60%;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10+ */
  user-select: none; /* Standard */
}

.message.system {
  background-color: #f0f0f0;
  align-self: flex-start;
}

.message.user {
  background-color: #daf1da;
  align-self: flex-end;
}

.message.loading {
  background-color: #fff3cd;
  align-self: center;
  text-align: center;
}

.message.error {
  background-color: #f8d7da;
  align-self: center;
  text-align: center;
}

.timestamp {
  display: block;
  font-size: 0.8em;
  color: #666;
  margin-top: 5px;
}

.Modal {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  transform: translate(-50%, -50%);
  background: #fff;
  padding: 2rem;
  border-radius: 8px;
  max-width: 500px;
  width: 90%;
  box-shadow: 0 5px 15px rgba(0,0,0,0.3);
}

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.5);
}

/* Styles for uploading preview */
.uploading-preview {
  position: relative;
  display: inline-block;
}

.uploading-preview img,
.uploading-preview video {
  opacity: 0.5;
  width: 100%;
  max-width: 300px; /* Ajuste conforme necessário */
  height: auto;
  border-radius: 8px;
}

.uploading-preview .spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 40px;
  height: 40px;
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top: 4px solid #3498db;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to { transform: rotate(360deg); }
}

/* Styles for chat media */
.chat-media {
  cursor: pointer;
  max-width: 100%;
  height: auto;
  border-radius: 8px;
}

/* Modal Styles */
.media-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.media-modal-content {
  position: relative;
  max-width: 90%;
  max-height: 90%;
}

.media-modal-content img,
.media-modal-content video {
  width: 100%;
  height: auto;
  border-radius: 8px;
}

.media-modal-close {
  position: absolute;
  top: -10px;
  right: -10px;
  background-color: #fff;
  border: none;
  border-radius: 50%;
  font-size: 20px;
  cursor: pointer;
  width: 30px;
  height: 30px;
}

/* Tickets.css */

/* Estilo para a sobreposição do modal */
.media-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000; /* Certifique-se de que está acima de outros elementos */
}

/* Estilo para o conteúdo do modal */
.media-modal-content {
  position: relative;
  background: #fff;
  padding: 20px;
  max-width: 90%;
  max-height: 90%;
  overflow: auto;
  border-radius: 8px;
  box-shadow: 0 5px 15px rgba(0,0,0,0.3);
}

/* Estilo para o botão de fechar */
.media-modal-close {
  position: absolute;
  top: 10px;
  right: 15px;
  background: transparent;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}

.custom-slide {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.lightbox-image {
  border-radius: 8px;
  cursor: pointer;
}

.zoom-controls {
  margin-top: 10px;
}

.zoom-controls button {
  margin: 0 5px;
  padding: 5px 10px;
  font-size: 16px;
  cursor: pointer;
}

.context-menu {
  position: absolute;
  background: white;
  border: 1px solid #ccc;
  list-style: none;
  padding: 5px 0;
  margin: 0;
  z-index: 1000;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}
.context-menu li {
  padding: 8px 12px;
  cursor: pointer;
}
.context-menu li:hover {
  background-color: #f0f0f0;
}

.ticket-timer-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffffff;
  width: 100%;
  margin: 10px auto;
}

.timer-section {
  font-size: 2.5rem;
  font-weight: 700;
  color: #007bff;
  margin-bottom: 12px;
  text-align: center;
  width: 100%;
}

.time-info-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.time-info-item {
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  color: #333333;
  background-color: #f7f7f7;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  padding: 8px 12px;
  margin: 0 4px;
}

.time-label {
  font-weight: 600;
  margin-right: 6px;
}

.time-value {
  color: #555;
}

.budget-panel.fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  background-color: white;
  overflow-y: auto;
  padding: 16px;
}

.mobile-header {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 16px;
}

.back-button {
  background: none;
  border: none;
  font-size: 1.2rem;
  color: #007bff;
  cursor: pointer;
}

.budget-panel.fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background-color: #ffffff;
  overflow-y: auto;
  padding: 16px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

.budget-panel-header.mobile-header {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 16px;
}

.budget-panel-header.mobile-header h3 {
  flex: 1;
  font-size: 1.2rem;
  margin: 0;
}

.back-button {
  background: none;
  border: none;
  font-size: 1.5rem;
  color: #007bff;
  cursor: pointer;
}