button.btn.btn-secondary.active {
    background: #008cff;
    border-color: #008cff;
}
  
  /* Animação de fade-in para o modal */
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translate(-50%, -40%);
    }
    to {
      opacity: 1;
      transform: translate(-50%, -50%);
    }
  }
  
  /* Cabeçalho do Modal */
  .ticket-modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #e0e0e0;
    margin-bottom: 15px;
    padding-bottom: 10px;
  }
  
  .ticket-modal-header h3 {
    font-size: 1.6rem;
    margin: 0;
    color: #222;
  }
  
  .close-button {
    background: transparent;
    border: none;
    font-size: 1.5rem;
    color: #aaa;
    cursor: pointer;
    transition: color 0.2s ease;
  }
  
  .close-button:hover {
    color: #333;
  }
  
  /* Corpo do Modal */
  .ticket-modal-body {
    max-height: 400px;
    overflow-y: auto;
    padding: 10px 0;
  }
  
  /* Lista de Tickets */
  .ticket-list {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  
  .ticket-item {
    margin-bottom: 12px;
  }
  
  .ticket-item button {
    width: 100%;
    text-align: left;
    background: #f5f5f5;
    border: 1px solid #ddd;
    border-radius: 6px;
    padding: 12px 15px;
    font-size: 1rem;
    color: #333;
    cursor: pointer;
    transition: background 0.2s, border-color 0.2s;
  }
  
  .ticket-item button:hover {
    background: #eaeaea;
    border-color: #ccc;
  }
  
  /* Estilização dos detalhes do ticket na listagem (caso queira incluir mais informações) */
  .ticket-detail {
    display: flex;
    justify-content: space-between;
    margin-top: 4px;
    font-size: 0.9rem;
    color: #555;
  }
  
  .ticket-detail span {
    display: block;
  }
  
  /* Rodapé do Modal */
  .ticket-modal-footer {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    margin-top: 20px;
  }
  
  .ticket-modal-footer button {
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    font-size: 0.9rem;
    cursor: pointer;
    transition: background-color 0.2s ease;
  }
  
  .ticket-modal-footer .btn-primary {
    background-color: #007bff;
    color: #fff;
  }
  
  .ticket-modal-footer .btn-primary:hover {
    background-color: #0056b3;
  }
  
  .ticket-modal-footer .btn-secondary {
    background-color: #6c757d;
    color: #fff;
  }
  
  .ticket-modal-footer .btn-secondary:hover {
    background-color: #565e64;
  }