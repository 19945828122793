/* Estilização da tabela */
.custom-table {
    border-collapse: collapse;
    width: calc(100% - var(--sidebar-width)); /* Ajusta com base na largura do sidebar */
    background-color: #ffffff; /* Fundo branco para contraste mais limpo */
    font-size: 14px;
    margin-bottom: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    table-layout: auto;
    transition: width 0.3s ease; /* Transição suave ao alterar largura */
}

:root {
    --sidebar-width: 240px; /* Define a largura padrão do sidebar */
}

body.sidebar-collapsed .custom-table {
    width: 100%; /* Ajusta para 100% quando o sidebar é retraído */
}

/* Estilização de cabeçalhos e células */
.custom-table th {
    background-color: #f5f5f5;
    text-align: left;
    font-weight: bold;
    padding: 12px;
    border-bottom: 2px solid #e0e0e0;
    position: relative;
    white-space: nowrap;
    color: #333333;
}

.custom-table td {
    padding: 10px;
    vertical-align: middle;
    border-bottom: 1px solid #e0e0e0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #555555;
}

/* Alternância de linhas */
.custom-table tr:nth-child(even) {
    background-color: #f9f9f9;
}

.custom-table tr:hover {
    background-color: #e6f7ff;
}

/* Estilização do tooltip */
.email-tooltip {
    position: absolute;
    cursor: pointer;
    color: #007bff;
    text-decoration: underline dotted;
    display: inline-block;
}

.email-tooltip:hover::after {
    content: attr(title);
    position: absolute;
    top: 50%; /* Centraliza verticalmente */
    left: 50%; /* Centraliza horizontalmente */
    transform: translate(-50%, -150%); /* Ajusta para o centro exato */
    z-index: 1050; /* Certifique-se de que este valor seja alto o suficiente */
    background: rgba(0, 0, 0, 0.9); /* Fundo escuro */
    color: #ffffff; /* Cor do texto */
    padding: 8px 12px; /* Espaçamento interno */
    border-radius: 6px; /* Bordas arredondadas */
    white-space: nowrap;
    font-size: 14px; /* Tamanho do texto */
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.4); /* Efeito de sombra */
    text-align: center; /* Centraliza o texto */
    animation: fadeIn 0.3s ease-in-out; /* Animação de aparecimento */
    pointer-events: none; /* Impede que o mouse interaja com o tooltip */
}

/* Ajuste para a seta do tooltip */
.email-tooltip:hover::before {
    content: '';
    position: absolute;
    bottom: 100%; /* Posição acima do tooltip */
    left: 50%;
    transform: translateX(-50%);
    border-width: 6px; /* Tamanho da setinha */
    border-style: solid;
    border-color: transparent transparent rgba(0, 0, 0, 0.9) transparent;
    z-index: 1049; /* Um nível abaixo do tooltip */
}

/* Animação para o tooltip */
@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

/* Garantia de contexto de empilhamento */
.custom-table {
    position: relative; /* Certifique-se de que a tabela tenha um contexto de empilhamento */
    z-index: 1; /* Um índice menor que o tooltip */
}

/* Botões de ação */
.action-buttons {
    display: flex;
    justify-content: space-around;
    gap: 8px;
}

.action-buttons button {
    padding: 6px;
    font-size: 12px;
    border: none;
    background-color: #007bff;
    color: #fff;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.action-buttons button:hover {
    background-color: #0056b3;
}

/* Ícones para botões */
.action-buttons i {
    font-size: 14px;
}

/* Paginador */
.pagination-btn {
    margin: 0 5px;
    padding: 5px 10px;
    font-size: 14px;
    border: 1px solid #ddd;
    border-radius: 3px;
    background-color: #f9f9f9;
    color: #007bff;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
}

.pagination-btn.active {
    background-color: #007bff;
    color: #fff;
    border-color: #0056b3;
}

.pagination-btn:hover {
    background-color: #0056b3;
    color: #fff;
}

/* Botão de ordenação */
.sortable {
    cursor: pointer;
    user-select: none;
    position: relative;
    white-space: nowrap;
}

.sortable .arrow {
    margin-left: 5px;
    color: #ccc;
    font-size: 12px;
}

.sortable .arrow.active {
    color: #007bff;
}

.sortable.asc .arrow-up {
    color: #007bff;
}

.sortable.desc .arrow-down {
    color: #007bff;
}

.sortable:hover {
    color: #0056b3;
}

/* Responsividade para smartphones */
@media (max-width: 768px) {
    .custom-table {
        font-size: 12px;
        width: 100%;
    }

    .custom-table th,
    .custom-table td {
        display: block;
        width: 100%;
        text-align: left;
        white-space: normal;
    }

    .custom-table td {
        border-bottom: none;
        padding: 8px 0;
    }

    .custom-table th {
        font-size: 12px;
        padding: 8px 0;
    }

    .action-buttons {
        flex-direction: column;
        gap: 4px;
    }
}

/* Estilização para o Modal */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6); /* Fundo escurecido */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1050;
    animation: fadeIn 0.3s ease-in-out;
}

.modal-content {
    background: linear-gradient(135deg, #ffffff, #f9f9f9);
    border-radius: 12px;
    width: 90%;
    max-width: 600px;
    padding: 25px 30px;
    box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.2);
    position: relative;
    animation: slideIn 0.3s ease-in-out;
    font-family: "Arial", sans-serif;
}

.modal-content h4 {
    margin-top: 0;
    font-size: 1.8rem;
    font-weight: bold;
    color: #333;
    text-align: center;
    margin-bottom: 15px;
}

.modal-content p {
    margin: 10px 0;
    font-size: 1rem;
    color: #555;
    line-height: 1.6;
    text-align: center;
}

.modal-content .close-button {
    position: absolute;
    top: 15px;
    right: 20px;
    background: none;
    border: none;
    font-size: 1.2rem;
    color: #888;
    cursor: pointer;
    transition: color 0.3s ease;
}

.modal-content .close-button:hover {
    color: #333;
}

.modal-content .modal-footer {
    display: flex;
    justify-content: space-around;
    gap: 15px;
    margin-top: 20px;
}

.modal-content .modal-footer button {
    flex: 1;
    padding: 10px 20px;
    font-size: 1rem;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    transition: background 0.3s ease, color 0.3s ease;
}

.modal-content .modal-footer .btn-primary {
    background-color: #007bff;
    color: white;
}

.modal-content .modal-footer .btn-primary:hover {
    background-color: #0056b3;
}

.modal-content .modal-footer .btn-secondary {
    background-color: #f5f5f5;
    color: #555;
}

.modal-content .modal-footer .btn-secondary:hover {
    background-color: #e0e0e0;
    color: #333;
}

/* Animação de Fade e Slide */
@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes slideIn {
    from {
        transform: translateY(-20px);
    }
    to {
        transform: translateY(0);
    }
}

/* Responsividade para telas menores */
@media (max-width: 768px) {
    .modal-content {
        width: 95%;
        padding: 20px;
    }

    .modal-content h4 {
        font-size: 1.5rem;
    }

    .modal-content p {
        font-size: 0.9rem;
    }
}

.sort-icons {
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    margin-left: 8px;
  }
  
  .sort-icons i {
    color: #ccc; /* Cor padrão para as flechas */
    transition: color 0.3s ease;
  }
  
  .sort-icons i.active {
    color: #007bff; /* Cor para a flecha ativa */
  }
  
  .sortable {
    cursor: pointer;
    position: relative;
  }
  
  .sortable:hover .sort-icons i {
    color: #007bff; /* Cor das flechas ao passar o mouse */
  }

  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1050;
}

.modal-content {
    background: #fff;
    border-radius: 8px;
    width: 90%;
    max-width: 500px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    padding: 20px;
    overflow: hidden;
    max-height: 80%; /* Restrição para a altura total */
}

.modal-dialog-scrollable {
    overflow-y: auto;
    max-height: 75vh; /* Define a altura máxima para rolagem */
    padding: 15px;
}

.modal-dialog-scrollable::-webkit-scrollbar {
    width: 8px;
}

.modal-dialog-scrollable::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border-radius: 4px;
}

.modal-dialog-scrollable::-webkit-scrollbar-thumb:hover {
    background-color: #aaa;
}

button {
    margin-top: 15px;
}