/* ServiceAutosuggest.css */

/* Container principal do Autosuggest */
.react-autosuggest__container {
    position: relative;
    width: 100%;
  }
  
  /* Quando o menu de sugestões está aberto */
  .react-autosuggest__container--open {
    z-index: 9999; /* para ficar acima de outros elementos */
  }
  
  /* A <input> em si, mas note que já inserimos "className='form-control'" via inputProps */
  .react-autosuggest__input {
    /* Se quiser reforçar algo específico sem quebrar a .form-control, pode fazer aqui */
    /* Example:
    box-shadow: none;
    transition: border-color 0.2s ease-in-out;
     */
  }
  
  /* Container que envolve as sugestões */
  .react-autosuggest__suggestions-container {
    display: none;
  }
  
  /* Quando está aberto */
  .react-autosuggest__suggestions-container--open {
    display: block;
    position: absolute;
    background-color: #fff;
    width: 100%;
    margin-top: 0.2rem;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
  }
  
  /* A <ul> que contém as sugestões */
  .react-autosuggest__suggestions-list {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  
  /* Cada sugestão individual <li> */
  .react-autosuggest__suggestion {
    padding: 8px 12px;
    cursor: pointer;
  }
  
  /* Hover/Highlight da sugestão */
  .react-autosuggest__suggestion--highlighted {
    background-color: #e9ecef; /* cor de highlight */
  }