/* Warehouse.css */
.warehouse-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f8f9fa;
    font-family: 'Arial', sans-serif;
    color: #333;
  }
  
  .warehouse-container h1 {
    text-align: center;
    margin-bottom: 30px;
    font-size: 2.5rem;
    color: #007bff;
  }
  
  /* Floating Cart Button */
  .floating-cart-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #007bff;
    border: none;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    color: #fff;
    font-size: 1.5rem;
    cursor: pointer;
    box-shadow: 0 4px 8px rgba(0,0,0,0.2);
    z-index: 2000;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform 0.3s ease;
  }
  
  .floating-cart-button:hover {
    transform: scale(1.1);
  }
  
  /* Cart Modal Styling */
  .CartModal {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    width: 300px;
    position: fixed;
    bottom: 90px;
    right: 20px;
    box-shadow: 0 4px 12px rgba(0,0,0,0.2);
    z-index: 2000;
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.3s ease, transform 0.3s ease;
  }
  
  .CartModal.ReactModal__Content--after-open {
    opacity: 1;
    transform: translateY(0);
  }
  
  .CartOverlay {
    background-color: rgba(0, 0, 0, 0);
    z-index: 2000;
  }
  
  /* Category and Subcategory Grids */
  .category-grid,
  .subcategory-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 20px;
    margin-bottom: 20px;
  }
  
  .category-card,
  .subcategory-card {
    position: relative;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 20px;
    text-align: center;
    cursor: pointer;
    transition: transform 0.2s, box-shadow 0.2s;
  }
  
  .category-card:hover,
  .subcategory-card:hover {
    transform: scale(1.03);
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  }
  
  /* Delete button: aparece apenas no hover */
  .category-card .delete-btn,
  .subcategory-card .delete-btn {
    display: none;
    position: absolute;
    top: 5px;
    right: 5px;
    background: transparent;
    border: none;
    color: #dc3545;
    cursor: pointer;
    font-size: 1rem;
  }
  
  .category-card:hover .delete-btn,
  .subcategory-card:hover .delete-btn {
    display: block;
  }
  
  /* Product List Grid - 3 produtos por linha */
  .product-list-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    margin-bottom: 20px;
  }
  
  .product-card {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 15px;
    transition: box-shadow 0.2s;
  }
  
  .product-card:hover {
    box-shadow: 0 4px 12px rgba(0,0,0,0.1);
  }
  
  .product-card h3 {
    margin-bottom: 10px;
    color: #007bff;
  }
  
  .product-card p {
    margin: 5px 0;
  }
  
  .barcode {
    text-align: center;
    margin: 10px 0;
  }
  
  .product-buttons {
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-top: 10px;
  }
  
  /* Top Add New Product Button */
  .top-product-btn {
    text-align: right;
    margin-bottom: 20px;
  }
  
  /* Modal Common Styles */
  .Modal {
    background-color: #fff;
    padding: 30px;
    border-radius: 8px;
    max-width: 600px;
    width: 90%;
    margin: auto;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    box-shadow: 0 4px 12px rgba(0,0,0,0.15);
  }
  
  .Overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
  }
  
  .modal-buttons {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    margin-top: 20px;
  }
  
  /* Product Form Styling - Vertical Layout (inputs empilhados) */
  .product-form.vertical {
    display: flex;
    flex-direction: column;
    gap: 15px;
    background-color: #fff;
    padding: 25px;
    border-radius: 8px;
    border: 1px solid #ddd;
    margin-bottom: 30px;
  }
  
  .product-form.vertical .form-group {
    display: flex;
    flex-direction: column;
  }
  
  .product-form.vertical .form-group label {
    margin-bottom: 8px;
    font-weight: bold;
  }
  
  .product-form.vertical .form-group input,
  .product-form.vertical .form-group select {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  /* Delete Confirmation Modal Input */
  .Modal input.form-control {
    margin-top: 10px;
  }
  
  /* Responsive Adjustments */
  @media (max-width: 768px) {
    .product-form.vertical {
      width: 100%;
    }
    .category-grid,
    .subcategory-grid,
    .product-list-grid {
      grid-template-columns: 1fr;
    }
    .CartModal {
      width: 80%;
      right: 10%;
    }
  }

  /* Estilização da barra de pesquisa de produtos */
.product-search-bar {
  position: relative;
  margin: 20px auto;
  max-width: 500px;
}

.product-search-bar input {
  width: 100%;
  padding: 10px 15px 10px 40px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 25px;
  outline: none;
  transition: border-color 0.3s ease;
}

.product-search-bar input:focus {
  border-color: #007bff;
}

/* Ícone de busca */
.product-search-bar::before {
  content: "\f002";
  font-family: "Font Awesome 5 Free"; /* Certifique-se de ter o FontAwesome incluído */
  font-weight: 900;
  position: absolute;
  left: 15px;
  top: 50%;
  transform: translateY(-50%);
  color: #aaa;
}

/* Ajustes responsivos */
@media (max-width: 768px) {
  .product-search-bar {
    max-width: 90%;
    margin: 15px auto;
  }
  
  .product-search-bar input {
    font-size: 14px;
    padding: 8px 12px 8px 35px;
  }
}

.print-area {
  position: absolute;
  left: -10000px;
  top: 0;
}

.history-results {
  background-color: #f7f7f7;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  margin-top: 20px;
}

.history-results h4 {
  margin-bottom: 15px;
  color: #333;
}

.history-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.history-item {
  padding: 10px;
  border-bottom: 1px solid #ddd;
}

.history-item:last-child {
  border-bottom: none;
}

.history-row {
  display: flex;
  margin-bottom: 5px;
}

.history-label {
  font-weight: bold;
  margin-right: 5px;
  color: #555;
  width: 120px; /* Largura fixa para alinhar os rótulos */
}

.history-value {
  color: #333;
}