/* Workers.css */

/* Container da página de trabalhadores */
.workers-page {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f8f9fa;
    font-family: 'Arial', sans-serif;
    color: #333;
  }
  
  /* Título da página */
  .workers-page h1 {
    text-align: center;
    margin-bottom: 30px;
    font-size: 2.5rem;
    color: #007bff;
  }
  
  /* Container do filtro */
  .filter-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .filter-container label {
    margin-right: 10px;
    font-weight: 500;
    font-size: 1rem;
    color: #333;
  }
  
  .filter-container input[type="month"] {
    padding: 8px;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  /* Tabela de trabalhadores */
  .workers-table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
    background-color: #fff;
    border: 1px solid #ddd;
  }
  
  .workers-table thead {
    background-color: #007bff;
    color: #fff;
  }
  
  .workers-table th,
  .workers-table td {
    padding: 12px 15px;
    text-align: left;
    border: 1px solid #ddd;
  }
  
  .workers-table tr:hover {
    background-color: #f1f1f1;
    cursor: pointer;
  }
  
  /* Visualização dos detalhes do trabalhador */
  .worker-details {
    max-width: 800px;
    margin: 40px auto;
    padding: 30px;
    background-color: #fff;
    border: 1px solid #e1e1e1;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  }
  
  .worker-details h2 {
    text-align: center;
    margin-bottom: 20px;
    font-size: 1.75rem;
    color: #007bff;
  }
  
  .worker-details p {
    margin-bottom: 12px;
    font-size: 1rem;
    color: #333;
  }
  
  .worker-details h3 {
    margin-top: 30px;
    font-size: 1.5rem;
    color: #007bff;
    border-bottom: 2px solid #e1e1e1;
    padding-bottom: 5px;
    margin-bottom: 15px;
  }
  
  /* Tabela de detalhamento diário */
  .daily-breakdown-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
  }
  
  .daily-breakdown-table th,
  .daily-breakdown-table td {
    padding: 10px;
    border: 1px solid #ddd;
    text-align: left;
  }
  
  .daily-breakdown-table th {
    background-color: #f8f8f8;
    font-weight: 600;
  }
  
  /* Botões */
  button {
    display: inline-block;
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  button:hover {
    background-color: #0056b3;
  }
  
  button:focus {
    outline: none;
  }
  
  /* Customização dos Toasts */
  .Toastify__toast {
    border-radius: 8px;
    font-size: 0.9rem;
  }