.profile-container {
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
    background: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }
  
  .profile-header {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .profile-form {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .form-group {
    display: flex;
    flex-direction: column;
  }
  
  .form-group label {
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  .form-control {
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 16px;
  }
  
  .form-control:focus {
    border-color: #007bff;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
  }
  
  .btn {
    padding: 10px 15px;
    font-size: 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background 0.3s ease;
  }
  
  .btn-primary {
    background: #007bff;
    color: #fff;
  }
  
  .btn-primary:hover {
    background: #0056b3;
  }

  .custom-select {
    display: flex;
    flex-direction: column;
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 5px;
  }
  
  .custom-option {
    display: flex;
    align-items: center;
    padding: 5px 10px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .custom-option img.language-icon {
    margin-right: 10px;
    width: 20px;
    height: 15px;
  }
  
  .custom-option:hover {
    background-color: #f1f1f1;
  }
  
  .custom-option.selected {
    font-weight: bold;
    background-color: #e6f7ff;
  }