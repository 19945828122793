/* sidebar.css */

.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  width: 250px;
  height: 100vh;
  background-color: #1f2937;
  color: #fff;
  overflow-y: auto;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, width 0.3s ease;
  z-index: 1000;
}

.sidebar.closed {
  transform: translateX(-250px); /* Esconde o sidebar quando fechado */
}

.sidebar-content {
  padding-top: 40px;
}

.sidebar-header {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
  border-bottom: 1px solid #2d3748;
}

.sidebar-header .logo-icon {
  width: 40px;
  height: 40px;
  margin-right: 10px;
}

.sidebar-nav {
  padding-top: 20px;
}

.sidebar-link {
  display: flex;
  align-items: center;
  padding: 12px 20px;
  color: #cbd5e0;
  font-size: 0.95rem;
  text-decoration: none;
  transition: background-color 0.2s ease, color 0.2s ease;
}

.sidebar-link:hover {
  background-color: #2b374b;
  color: #edf2f7;
}

.sidebar-link i {
  margin-right: 12px;
  font-size: 1.2rem;
}

.metismenu ul {
  padding-left: 20px;
  list-style-type: none; /* Remove marcadores de lista */
}

.metismenu li {
  position: relative;
}

.metismenu li a {
  color: #a0aec0;
  padding: 8px 20px;
  display: block;
  text-decoration: none;
  transition: color 0.2s ease;
}

.metismenu li a:hover {
  color: #63b3ed;
}

.metismenu ul li::before {
  content: none; /* Remove os pontos antes dos itens */
}

ul {
  padding-left: 0;
  list-style-type: none; /* Remove marcadores de lista */
}

/* Botão de fechamento */
.sidebar .close-btn {
  position: absolute;
  top: 15px;
  right: 15px;
  background: none;
  border: none;
  color: #fff;
  font-size: 1.5rem;
  cursor: pointer;
}

/* Responsividade */
@media (max-width: 768px) {
  .sidebar {
    width: 200px;
  }
  
  .sidebar.closed {
    transform: translateX(-200px); /* Ajusta para esconder */
  }

  .sidebar-link {
    font-size: 0.85rem;
  }

  .sidebar-link i {
    font-size: 1rem;
  }
}

@media (max-width: 480px) {
  .sidebar {
    width: 180px;
  }

  .sidebar.closed {
    transform: translateX(-180px); /* Ajusta para esconder */
  }

  .sidebar-link {
    font-size: 0.8rem;
  }
}

/* Estilo para o item ativo do menu */
.sidebar-link.mm-active,
.sidebar-link.mm-active:hover {
  background-color: #2b6cb0; /* Azul elegante para destacar */
  color: #ffffff; /* Cor do texto */
  border-left: 4px solid #63b3ed; /* Linha destacando o item ativo */
  font-weight: bold; /* Negrito para dar ênfase */
  transition: background-color 0.2s ease, color 0.2s ease;
}

/* Estilo para ícones no item ativo */
.sidebar-link.mm-active i {
  color: #ffffff; /* Cor do ícone ativo */
}

/* Submenu ativo */
.metismenu li a.mm-active {
  background-color: #2b6cb0;
  color: #ffffff;
  font-weight: bold;
  border-left: 4px solid #63b3ed;
}

/* Hover para os itens do submenu */
.metismenu li a.mm-active:hover {
  background-color: #2c5282; /* Azul mais escuro no hover */
  color: #e2e8f0;
}

/* Efeito visual no menu colapsado */
.sidebar.closed .sidebar-link.mm-active {
  background-color: transparent;
  border-left: none;
  font-weight: normal;
}