.budget-panel {
    /* Faz o painel flutuar sobre o resto da página */
    position: fixed;
    bottom: 2rem;
    right: 2rem;
    width: 1000px;
    max-height: 80vh; /* Limita a altura a 80% da viewport */
    
    display: flex;
    flex-direction: column;
  
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.2);
    z-index: 9999; /* Certifique-se de ficar sobre outros elementos */
    
    overflow: hidden; /* Se o conteúdo ultrapassar, haverá scroll (ou ocultar) */
    transition: transform 0.3s ease, opacity 0.3s ease, width 0.3s ease;
  }
  
  /* Seção de resumo integrada ao painel */
.budget-summary-section {
  margin-top: 1rem;
  padding: 0 1rem 1rem;
  background-color: #f9f9f9;
  border-top: 1px solid #e0e0e0;
}

/* Card do resumo */
.budget-summary-card {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  background-color: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 0.75rem 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Linhas do resumo */
.budget-summary-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* Estilo dos rótulos */
.budget-summary-row .label {
  font-weight: bold;
  color: #333;
  font-size: 0.95rem;
}

/* Estilo dos valores */
.budget-summary-row .value {
  font-size: 1rem;
  color: #007bff;
}

/* Container para as ações e o resumo, exibidos em duas colunas */
.budget-bottom-section {
  display: flex;
  justify-content: space-between;
  align-items: flex-end; /* Alinha os elementos na base */
  margin-top: 1rem;
  padding: 0 1rem;
}

/* Botões no canto esquerdo */
.budget-actions-left {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}

/* Resumo no canto direito */
.budget-summary-right {
  text-align: right;
}

/* Opcional: Ajuste o tamanho do resumo, se necessário */
.budget-summary-right .budget-summary-card {
  max-width: 250px;
}
  
  .budget-panel-header {
    background: #f2f2f2;
    padding: 0.5rem;
    border-bottom: 1px solid #ccc;
    display: flex;
    justify-content: space-between; 
    align-items: center;
    cursor: grab; /* Indica que pode ser arrastado */
    user-select: none;
  }
  
  .budget-panel-header h3 {
    margin: 0;
    font-size: 1.1rem;
    font-weight: 600;
  }
  
  .budget-panel-controls {
    display: flex;
    gap: 0.25rem;
  }
  
  .budget-panel-controls button {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 1.2rem;
    padding: 0.2rem 0.5rem;
    border-radius: 4px;
  }
  
  .budget-panel-controls button:hover {
    background: #ddd;
  }
  
  .budget-panel-content {
    flex: 1; /* Ocupa o restante do espaço */
    overflow-y: auto; /* Se o conteúdo for maior que a altura, rola */
    padding: 0.5rem;
  }
  
  /* Estilos da tabela, se necessário */
  .budget-panel-content table {
    width: 100%;
    border-collapse: collapse;
    background-color: #fff;
  }
  
  .budget-panel-content th,
  .budget-panel-content td {
    border: 1px solid #ddd;
    padding: 0.75rem;
    vertical-align: middle;
  }
  
  /* Quando estiver "minimized" */
  .budget-panel.minimized {
    height: auto;
    width: 300px;
    overflow: hidden;
  }
  
  /* Oculta o conteúdo, mas mantendo o header */
  .budget-panel.minimized .budget-panel-content {
    display: none; /* Ou visibility: hidden; ou height: 0, etc. */
  }
  
  /* Responsivo no mobile */
  @media (max-width: 768px) {
    .budget-panel {
      bottom: 1rem;
      right: 1rem;
      width: 90%; /* Maioria da tela no mobile */
      max-height: 70vh;
    }
  }

  .budget-panel-header:active {
    cursor: grabbing;
  }