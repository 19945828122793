.user-list-container {
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
  h2 {
    margin-bottom: 20px;
  }
  
  .user-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
  }
  
  .user-table th,
  .user-table td {
    border: 1px solid #ddd;
    padding: 10px;
    text-align: left;
  }
  
  .user-table th {
    background-color: #f4f4f4;
  }
  
  select {
    padding: 5px;
  }
  
  input[type="checkbox"] {
    transform: scale(1.2);
  }
  
  .ticket-button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 4px;
  }
  
  .ticket-button:hover {
    background-color: #0056b3;
  }

  /* userList.css */

/* Estilos para o overlay do modal */
.custom-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

/* Estilos para o conteúdo do modal */
.custom-modal {
  background: #fff;
  padding: 20px 30px;
  border-radius: 8px;
  max-width: 500px;
  width: 90%;
  max-height: 90vh;
  overflow-y: auto;
  position: relative;
}

/* Botão de fechar (opcional) */
.custom-modal .close-button {
  position: absolute;
  top: 10px;
  right: 15px;
  background: none;
  border: none;
  font-size: 1.5rem;
}